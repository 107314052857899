module.exports = {
  currentUrl: '',
  options: [],
  categories: {
    diagnosis: {
      name: 'diagnosis',
      display: 'Diagnosis',
    },
    monitoring: {
      name: 'monitoring',
      display: 'Monitoring',
    },
    treatment: {
      name: 'treatment',
      display: 'Control',
    },
    exclusions: {
      name: 'exclusions',
      display: 'Exclusions',
    },
  },
  charts: {},
  pathwayId: 'htn',
  colors: [
    '#1f77b4',
    '#ff7f0e',
    '#2ca02c',
    '#d62728',
    '#9467bd',
    '#8c564b',
    '#e377c2',
    '#7f7f7f',
    '#bcbd22',
    '#17becf',
  ],
  monitored: {
    bp: 'Blood Pressure',
    asthma: 'Peak Expiratory Flow',
  },
};
