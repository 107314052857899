const data = require('../data.js');
const state = require('../state.js');
const log = require('../log.js');
const patientSearchTemplate = require('../templates/patient-search.jade');

let states;
let loadContFn;
let $searchBox;

const ps = {
  wireUp($container, patientSelectedHandler, changeHandler) {
    $searchBox = $container;

    if (states) {
      states.clearPrefetchCache();
    }

    data.populateNhsLookup(state.selectedPractice._id, () => {
      states = new Bloodhound({
        datumTokenizer: Bloodhound.tokenizers.obj.whitespace('value'),
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        local: $.map(
          Object.keys(data.patLookup[state.selectedPractice._id]),
          (stt) => ({
            id: stt,
            value:
              data.patLookup[state.selectedPractice._id] &&
              data.patLookup[state.selectedPractice._id][stt]
                ? data.patLookup[state.selectedPractice._id][stt]
                    .toString()
                    .replace(/ /g, '')
                : stt,
          })
        ),
      });

      states.initialize(true);

      const $typeAhead = $container.find('.typeahead');

      $typeAhead.typeahead('destroy');
      $typeAhead
        .typeahead(
          {
            hint: true,
            highlight: true,
            minLength: 1,
            autoselect: true,
          },
          {
            name: 'patients',
            displayKey: 'value',
            source: states.ttAdapter(),
            templates: {
              empty: [
                '<div class="empty-message">',
                '&nbsp; &nbsp; No matches',
                '</div>',
              ].join('\n'),
            },
          }
        )
        .on('typeahead:selected', patientSelectedHandler)
        .on('typeahead:autocompleted', patientSelectedHandler)
        .on('paste', (e) => {
          e.preventDefault();
          let pastedText = '';
          if (window.clipboardData && window.clipboardData.getData) {
            // IE
            pastedText = window.clipboardData.getData('Text');
          } else if (
            e.originalEvent &&
            e.originalEvent.clipboardData &&
            e.originalEvent.clipboardData.getData
          ) {
            pastedText = e.originalEvent.clipboardData.getData('text/plain');
          }
          $typeAhead.typeahead('val', pastedText.replace(/\D/g, ''));
        });
      if (changeHandler && typeof changeHandler === 'function') {
        $typeAhead.on('typeahead:change', changeHandler);
      }
    });
  },

  // default patient selected handler, used on the Patients tab
  onSelected($e, nhsNumberObject) {
    // Hide the suggestions panel
    $searchBox.find('.tt-dropdown-menu').css('display', 'none');
    log.event(
      'patient-search',
      window.location.hash,
      state.selectedPractice._id,
      [{ key: 'patid', value: nhsNumberObject.id }]
    );
    window.history.pushState(null, null, `#patient/${nhsNumberObject.id}`);
    loadContFn(`#patient/${nhsNumberObject.id}`, true);
  },

  // default show() method, used on the patients tab
  show(panel, isAppend, isPatientSelected, loadContentFn) {
    const isDataLoaded = !!(
      data.patLookup && data.patLookup[state.selectedPractice._id]
    );

    loadContFn = loadContentFn;
    let tmpl = patientSearchTemplate;
    let html = tmpl({
      dataLoaded: isDataLoaded,
      patientSelected: isPatientSelected,
    });

    if (isAppend) panel.append(html);
    else panel.html(html);

    // $('#search-box').find('.typeahead').typeahead(); //so it looks ok even while data loading

    const waitUntilDataLoaded = () => {
      setTimeout(() => {
        if (!data.patLookup || !data.patLookup[state.selectedPractice._id]) {
          waitUntilDataLoaded();
        } else {
          tmpl = patientSearchTemplate;
          html = tmpl({ dataLoaded: true, patientSelected: isPatientSelected });
          $('#search-box').replaceWith(html);

          setTimeout(() => {
            ps.wireUp($('#search-box'), ps.onSelected);
          }, 0);
        }
      }, 500);
    };

    if (!isDataLoaded) {
      waitUntilDataLoaded();
    } else {
      setTimeout(() => {
        ps.wireUp($('#search-box'), ps.onSelected);
      }, 0);
    }
  },

  // allows creation of patient search boxes in other parts of the UI
  // assumes caller has already created the DOM elements and has a
  // handler method for when a patient is selected and an optional
  // handler method for when the search string is changed
  showAsLightweightWidget(
    containerElementSelector,
    patientSelectedHandler,
    changeHandler
  ) {
    const isDataLoaded = !!(
      data.patLookup && data.patLookup[state.selectedPractice._id]
    );

    const waitUntilDataLoaded = () => {
      setTimeout(() => {
        if (!data.patLookup || !data.patLookup[state.selectedPractice._id]) {
          waitUntilDataLoaded();
        } else {
          setTimeout(() => {
            ps.wireUp(
              $(containerElementSelector),
              patientSelectedHandler,
              changeHandler
            );
          }, 0);
        }
      }, 500);
    };

    if (!isDataLoaded) {
      waitUntilDataLoaded();
    } else {
      setTimeout(() => {
        ps.wireUp(
          $(containerElementSelector),
          patientSelectedHandler,
          changeHandler
        );
      }, 0);
    }
  },
};

module.exports = ps;
