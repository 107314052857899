if noStandards
  #qs
    .alert.alert-success We don't think any of the quality indicators in PINGR are relevant to this patient - if you think this is incorrect, please tell us by clicking the "Send a comment" button in the top right corner of the screen.
else
 div#qs.panel.panel-default(style="margin-bottom:15px")
    div.panel-heading.panel-heading-toggle.open(data-toggle="tooltip" data-placement="left" title="" data-original-title="Click to show/hide this patient's Quality standards" style="font-weight:normal")
      div.panel-heading-title  
        a.show-more.show-icon
          | Quality standards 
          i.fa.fa-caret-down
        a.show-more.hide-icon
          | Quality standards 
          i.fa.fa-caret-up
      div.panel-heading-expand-link(style="margin-left:32%")
        a.show-more.show-icon
          | Show more 
          i.fa.fa-caret-down
        a.show-more.hide-icon
          | Show less 
          i.fa.fa-caret-up
      div(style="clear:both")
    div.panel-body.standardsPanelBody
      ul.nav.nav-tabs(role="tablist")
        li(class=!selectedTab || selectedTab==='#processIndicatorsQS' ? "active" : "",role="presentation")
          a(href="#processIndicatorsQS",aria-controls="processIndicatorsQS",role="tab",data-toggle="tab",style="margin-top:0")= "Process indicators (" + processStandards.length + ")"
        li(class=selectedTab==='#outcomeIndicatorsQS' ? "active" : "",role="presentation")
          a(href="#outcomeIndicatorsQS",aria-controls="outcomeIndicatorsQS",role="tab",data-toggle="tab",style="margin-top:0")= "Outcome indicators (" + outcomeStandards.length + ")"
          
      .tab-content
        #processIndicatorsQS.tab-pane.fade(class=!selectedTab || selectedTab==='#processIndicatorsQS' ? "active in" : "",role="tabpanel")
          .panel.panel-default.square-panel.small-margin-panel
            .panel-body.no-padding
              table.table.table-condensed.qs-table
                thead
                  tr
                    th Quality indicator
                    th(style="max-width:100px")
                      span.show-inline-1366 Target met?
                      span.hide-1366 Met?
                    th(style="max-width:100px")
                      span.show-inline-1366 Action plan?
                      span.hide-1366 Plan?
                    th.qs-button-header.text-right
                      if excludedFromAllIndicators
                        button.btn.btn-default.btn-sm.show-inline-1600.includeAll.attach-tooltip-when-hidden(style='opacity:1', data-container='body', data-html='true', data-toggle='tooltip', data-placement='left', title=excludedFromAllIndicatorsTooltip + '<div style="padding-top:5px">Click to include this patient for all indicators.</div>') Excluded from all
                        button.btn.btn-default.btn-xs.hide-1600.includeAll.attach-tooltip-when-hidden(style='opacity:1', data-container='body', data-html='true', data-toggle='tooltip', data-placement='left', title=excludedFromAllIndicatorsTooltip + '<div style="padding-top:5px">Click to include this patient for all indicators.</div>') Excluded from all
                      else
                        button.btn.btn-danger.btn-sm.show-inline-1600.excludeAll.attach-tooltip-when-hidden(data-toggle='tooltip', data-container='body', data-html='true', data-placement='left', title='Exclude patient from all indicators') Exclude from all
                        button.btn.btn-danger.btn-xs.hide-1600.excludeAll.attach-tooltip-when-hidden(data-toggle='tooltip', data-container='body', data-html='true', data-placement='left', title='Exclude patient from all indicators') Exclude from all
                tbody
                  each standard in processStandards
                    tr(class=standard.excluded ? 'active' : (standard.targetMet ? 'success' : 'danger'))
                      td(style=standard.excluded ? 'opacity:0.5' : '')
                        strong
                          = standard.display 
                        a.reason-link.pull-right.show-inline-1600(href='#',data-id=standard.indicatorId) 
                          span.show-more-text Show more 
                            i.fa.fa-caret-down
                          span.show-less-text.hidden Show less 
                            i.fa.fa-caret-up
                        a.reason-link.pull-right.hide-1600(href='#',data-id=standard.indicatorId) 
                          span.show-more-text More 
                            i.fa.fa-caret-down
                          span.show-less-text.hidden Less 
                            i.fa.fa-caret-up
                      td(style=standard.excluded ? 'opacity:0.5' : '')
                        if standard.targetMet
                          i.fa.fa-fw.fa-check.text-success
                        else
                          i.fa.fa-fw.fa-times.text-danger      
                      td(style=standard.excluded ? 'opacity:0.5' : '')
                        if standard.actionPlan
                          i.fa.fa-fw.fa-check.text-success
                      td(style=standard.excluded ? 'text-align:right;background-color:rgba(245,245,245,0.5)' : 'text-align:right;')
                        if !standard.excluded
                          if (standard.indicatorId === indicatorId && (outcomeStandards.filter(function(v){return !v.targetMet;}).length + processStandards.filter(function(v){return !v.targetMet;}).length) > 1)
                            a.btn.btn-warning.btn-sm.show-inline-1600(href='/#patient/'+patientId,disabled=standard.excluded,class=standard.excluded ? 'disabled':'') Showing actions for this indicator - click to show all
                            a.btn.btn-warning.btn-xs.hide-1600(href='/#patient/'+patientId,disabled=standard.excluded,class=standard.excluded ? 'disabled':'') Showing actions for this indicator - click to show all
                          else if (!standard.targetMet && (outcomeStandards.filter(function(v){return !v.targetMet;}).length + processStandards.filter(function(v){return !v.targetMet;}).length) === 1)
                            span Currently showing actions for this indicator 
                          else 
                            if !standard.targetMet
                              a.btn.btn-info.btn-sm.show-inline-1600(href='/#patient/'+patientId+'/'+standard.indicatorId.replace(/\./g,"/"),disabled=standard.excluded,class=standard.excluded ? 'disabled':'') Show actions for this indicator only
                              a.btn.btn-info.btn-xs.hide-1600(href='/#patient/'+patientId+'/'+standard.indicatorId.replace(/\./g,"/"),disabled=standard.excluded,class=standard.excluded ? 'disabled':'') Show actions for this indicator only
                          
                        if !excludedFromAllIndicators
                          if standard.excluded
                            button.btn.btn-default.btn-sm.show-inline-1600.include.attach-tooltip-when-hidden(data-id=standard.indicatorId, style='opacity:1', data-container='body', data-html='true', data-toggle='tooltip', data-placement='left', title=standard.excludedTooltip + '<div style="padding-top:5px">Click to include this patient for this indicator.</div>') Excluded
                            button.btn.btn-default.btn-xs.hide-1600.include.attach-tooltip-when-hidden(data-id=standard.indicatorId, style='opacity:1', data-container='body', data-html='true', data-toggle='tooltip', data-placement='left', title=standard.excludedTooltip + '<div style="padding-top:5px">Click to include this patient for this indicator.</div>') Excluded
                          else
                            button.btn.btn-danger.btn-sm.show-inline-1600.exclude.attach-tooltip-when-hidden(data-id=standard.indicatorId,data-toggle='tooltip', data-container='body', data-html='true', data-placement='left', title='Exclude patient from ' + standard.display) Exclude
                            button.btn.btn-danger.btn-xs.hide-1600.exclude.attach-tooltip-when-hidden(data-id=standard.indicatorId,data-toggle='tooltip', data-container='body', data-html='true', data-placement='left', title='Exclude patient from ' + standard.display) Exclude
                    tr(data-id=standard.indicatorId,class='qs-show-more-row')
                      td(colspan=4)
                        !=standard.indicatorDescription
                        br
                        strong= "Reason for this patient " + (standard.targetMet ? "meet" : "miss") + "ing the target"
                        br
                        !=standard.why
        #outcomeIndicatorsQS.tab-pane.fade(class=selectedTab==='#outcomeIndicatorsQS' ? "active in" : "",role="tabpanel")
          .panel.panel-default.square-panel.small-margin-panel
            .panel-body.no-padding
              table.table.table-condensed.qs-table
                thead
                  tr
                    th(style="max-width:300px") Quality indicator
                    th.qs-button-header.text-right
                      if excludedFromAllIndicators
                        button.btn.btn-default.btn-sm.show-inline-1600.includeAll.attach-tooltip-when-hidden(style='opacity:1', data-container='body', data-html='true', data-toggle='tooltip', data-placement='left', title=excludedFromAllIndicatorsTooltip + '<div style="padding-top:5px">Click to include this patient for all indicators.</div>') Excluded from all
                        button.btn.btn-default.btn-xs.hide-1600.includeAll.attach-tooltip-when-hidden(style='opacity:1', data-container='body', data-html='true', data-toggle='tooltip', data-placement='left', title=excludedFromAllIndicatorsTooltip + '<div style="padding-top:5px">Click to include this patient for all indicators.</div>') Excluded from all
                      else
                        button.btn.btn-danger.btn-sm.show-inline-1600.excludeAll.attach-tooltip-when-hidden(data-toggle='tooltip', data-container='body', data-html='true', data-placement='left', title='Exclude patient from all indicators') Exclude from all
                        button.btn.btn-danger.btn-xs.hide-1600.excludeAll.attach-tooltip-when-hidden(data-toggle='tooltip', data-container='body', data-html='true', data-placement='left', title='Exclude patient from all indicators') Exclude from all
                tbody
                  each standard in outcomeStandards
                    tr(class=standard.excluded ? 'active' : (standard.targetMet ? 'active' : 'danger'))
                      td(style=standard.excluded ? 'opacity:0.5' : '')
                        strong
                          = standard.display
                        a.reason-link.pull-right(href='#',data-id=standard.indicatorId)
                          span.show-more-text Show more 
                            i.fa.fa-caret-down
                          span.show-less-text.hidden Show less 
                            i.fa.fa-caret-up
                      td(style=standard.excluded ? 'text-align:right;background-color:rgba(245,245,245,0.5)' : 'text-align:right;')
                        if !standard.targetMet
                          if !standard.excluded
                            if (standard.indicatorId === indicatorId && (outcomeStandards.filter(function(v){return !v.targetMet;}).length + processStandards.filter(function(v){return !v.targetMet;}).length) > 1)
                              a.btn.btn-warning.btn-sm.show-inline-1600(href='/#patient/'+patientId,disabled=standard.excluded,class=standard.excluded ? 'disabled':'') Showing actions for this indicator - click to show all
                              a.btn.btn-warning.btn-xs.hide-1600(href='/#patient/'+patientId,disabled=standard.excluded,class=standard.excluded ? 'disabled':'') Showing actions for this indicator - click to show all
                            else if (!standard.targetMet && (outcomeStandards.filter(function(v){return !v.targetMet;}).length + processStandards.filter(function(v){return !v.targetMet;}).length) === 1)
                              span Currently showing actions for this indicator
                            else
                              if !standard.targetMet
                                a.btn.btn-info.btn-sm.show-inline-1600(href='/#patient/'+patientId+'/'+standard.indicatorId.replace(/\./g,"/"),disabled=standard.excluded,class=standard.excluded ? 'disabled':'') Show actions for this indicator only
                                a.btn.btn-info.btn-xs.hide-1600(href='/#patient/'+patientId+'/'+standard.indicatorId.replace(/\./g,"/"),disabled=standard.excluded,class=standard.excluded ? 'disabled':'') Show actions for this indicator only

                          if !excludedFromAllIndicators
                            if standard.excluded
                              button.btn.btn-default.btn-sm.show-inline-1600.include.attach-tooltip-when-hidden(data-id=standard.indicatorId, style='opacity:1', data-container='body', data-html='true', data-toggle='tooltip', data-placement='left', title=standard.excludedTooltip + '<div style="padding-top:5px">Click to include this patient for this indicator.</div>') Excluded
                              button.btn.btn-default.btn-xs.hide-1600.include.attachTooltattach-tooltip-when-hiddenipWhenHidden(data-id=standard.indicatorId, style='opacity:1', data-container='body', data-html='true', data-toggle='tooltip', data-placement='left', title=standard.excludedTooltip + '<div style="padding-top:5px">Click to include this patient for this indicator.</div>') Excluded
                            else
                              button.btn.btn-danger.btn-sm.show-inline-1600.exclude.attach-tooltip-when-hidden(data-id=standard.indicatorId,data-toggle='tooltip', data-container='body', data-html='true', data-placement='left', title='Exclude patient from ' + standard.display) Exclude
                              button.btn.btn-danger.btn-xs.hide-1600.exclude.attach-tooltip-when-hidden(data-id=standard.indicatorId,data-toggle='tooltip', data-container='body', data-html='true', data-placement='left', title='Exclude patient from ' + standard.display) Exclude
                    tr(data-id=standard.indicatorId,class='qs-show-more-row')
                      td(colspan=4)
                        !=standard.indicatorDescription
                        br
                        strong= "Reason for this patient " + (standard.targetMet ? "meet" : "miss") + "ing the target"
                        br
                        !=standard.why
