.panel.panel-default.top-half-of-action-panel(style='margin-bottom:10px')
  .clearfix.panel-heading!=title
  #team-action-panel.panel-body.no-padding
    #advice-list
.panel.panel-default.bottom-half-of-action-panel(style='margin-bottom:5px')
  #user-action-panel.panel-body.no-padding
    #personalPlanTeam
include mixins/edit-plan-modal
include mixins/delete-plan-modal
include mixins/choose-assigned-user-modal
