mixin tableControls(practiceUsers, tableType)
  div(style="border-left:1px solid #ddd;border-right:1px solid #ddd;padding:10px 10px 0 10px")
    .form-inline
      div.btn-group.all-or-my-actions-widget(role="group" style="margin-right:10px;margin-bottom:10px")
        button.view-my-actions-btn.btn.btn-default.attach-tooltip-when-hidden(type="button" data-toggle="tooltip" data-placement="top" title='See only the actions assigned to me') 
          i.fa.fa-fw.fa-user
        button.view-all-actions-btn.btn.btn-default.attach-tooltip-when-hidden(type="button" data-toggle="tooltip" data-placement="top" title='See actions assigned to all users in my practice') 
          i.fa.fa-fw.fa-users
      select.whose-actions-select(data-live-search='true', data-live-search-placeholder='Search for a team member...')
        option(value='everyone') Everyone's actions
        each u in practiceUsers
          option(value=u.fullname)= u.fullname === currentUserName ? u.fullname + '\'s (my) actions' : u.fullname + '\'s actions'
      div.btn-group(role="group" style="margin-right:10px;margin-bottom:10px")
        button.hideDone.hide-done-actions-btn.btn.btn-default.attach-tooltip-when-hidden(type="button" data-toggle="tooltip" data-placement="top" title='Click to hide/view actions that have been marked as completed') 
          | Show completed
          span.hidden-sm  actions
      input.form-control.search-actions.attach-tooltip-when-hidden(placeholder="Search ..." style="width:300px;margin-right:10px;margin-bottom: 10px"  data-toggle="tooltip" data-placement="top" title="Enter text to filter the list of actions")
      div.btn-group.pull-right.save-action-plan-widgets(style="margin-left:10px;margin-bottom:10px")
        button.btn.btn-warning.dropdown-toggle(type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false") Save action plan 
          span.caret
        ul.dropdown-menu
          li 
            a.save-as-pdf-btn(href="#")
              i.fa.fa-fw.fa-file-pdf  
              |  Save as PDF
          li 
            a.save-as-excel-btn(href="#")
              i.fa.fa-fw.fa-file-excel  
              |  Save as Excel
          li 
            a.copy-to-clipboard-btn(href="#")
              i.fa.fa-fw.fa-copy  
              |  Copy to clipboard
      button.add-userdefined-action-btn.btn.btn-warning.attach-tooltip-when-hidden.pull-right(type="button" data-toggle="tooltip" data-placement="top" title='Create a new action and add it to the plan' style="margin-left:10px;margin-bottom:10px")
        | Add new #{tableType} action

ul.nav.nav-tabs(role='tablist')
  li.active#patient-actions-tab(role='presentation')
    a(href='#patientActionsListPanel', aria-controls='patientActionsListPanel', role='tab', data-toggle='tab'  data-content="patientActionsList" style="margin-top:0") Patient actions
  li#team-actions-tab(role='presentation')
    a(href='#teamActionsListPanel', aria-controls='teamActionsListPanel', role='tab', data-toggle='tab'  data-content="teamActionsList" style="margin-top:0") Team actions

div.tab-content#actionPlanTablesContainer
  div.tab-pane.active(id='patientActionsListPanel', role='tabpanel')
    #patient-actions-controls
      +tableControls(practiceUsers, 'patient')
    #patient-actions-table-wrapper(style="border-left:1px solid #ddd;border-right:1px solid #ddd;border-bottom:1px solid #ddd;margin-bottom: 4px")

  div.tab-pane(id='teamActionsListPanel', role='tabpanel')
    #team-actions-controls
      +tableControls(practiceUsers, 'team')
    #team-actions-table-wrapper(style="border-left:1px solid #ddd;border-right:1px solid #ddd;border-bottom:1px solid #ddd;margin-bottom: 4px")

#action-plans-modal-wrapper

//- used for export to Excel
script(src="https://cdnjs.cloudflare.com/ajax/libs/jszip/3.1.3/jszip.min.js",crossorigin="anonymous")
include ../../server/views/partials/bootstrap-select.jade
