const data = require('../data');
const base = require('../base');
const state = require('../state');
const lookup = require('../lookup');
const log = require('../log.js');
const JSPDF = require('jspdf');
const patientSearch = require('../panels/patientSearch');
const actionListWrapperTmpl = require('../templates/action-list-wrapper.jade');
const actionListTemplate = require('../templates/action-list.jade');
const actionListModalsTemplate = require('../templates/action-list-modals.jade');
const actionListRowTemplate = require('../templates/action-list-row-wrapper.jade');

const standardAsc = (a, b) => {
  if (a < b) return -1;
  return a > b ? 1 : 0;
};

const standardDesc = (a, b) => -standardAsc(a, b);

$.extend($.fn.dataTableExt.oSort, {
  'date-uk-pre': (a) => {
    if (a === null || a === '' || a === '?') {
      return 0;
    }
    const ukDatea = a.split('/');
    return (
      (ukDatea[2] +
        `0${ukDatea[1]}`.substr(ukDatea[1].length - 1) +
        `0${ukDatea[0]}`.substr(ukDatea[0].length - 1)) *
      1
    );
  },
  'date-uk-asc': standardAsc,
  'date-uk-desc': standardDesc,
});

$.fn.dataTable.ext.search.push((settings, searchData, index) => {
  // Show all rows if required
  const $hideCompletedButton = $(`#${settings.sTableId}`)
    .parents('.tab-pane:first')
    .find('button.hide-done-actions-btn:first');
  if (!$hideCompletedButton.hasClass('hideDone')) {
    return true;
  }
  // Hide completed actions
  const table = $(`#${settings.sTableId}`).DataTable();
  return !$(table.row(index).node()).hasClass('action-completed');
});

const saveTableAsPDF = (dt) => {
  let filename;
  const columns = [
    'Created by',
    'Agreed by',
    'Date',
    'Assigned to',
    'Action',
    'Indicator(s)',
    'Completed',
  ];
  let rows = dt.rows({ search: 'applied' }).data().toArray();
  const pdfOpts = {
    styles: { overflow: 'linebreak' },
    columnStyles: {
      0: { columnWidth: 70 },
      1: { columnWidth: 70 },
      2: { columnWidth: 70 },
      3: { columnWidth: 70 },
    },
  };
  if (dt.table().node().id === 'suggested-patient-actions-table') {
    filename = 'PINGR Patient actions.pdf';
    columns.unshift('NHS no.');
    rows = rows.map((v) => {
      const cols = [];
      cols.push($(v[0]).text());
      cols.push(v[1]);
      cols.push(v[2]);
      cols.push(v[3]);
      cols.push($(v[4]).html());
      cols.push($(v[5]).html());
      const str = $(v[6])
        .find('a')
        .map((i, e) => $(e).text())
        .get()
        .join(', ');
      cols.push(str);
      cols.push(
        $(v[7]).find('input[type="checkbox"]').val() === '1' ? 'Yes' : 'No'
      );
      return cols;
    });
    pdfOpts.columnStyles[6] = { columnWidth: 70 };
    pdfOpts.columnStyles[7] = { columnWidth: 70 };
  } else {
    filename = 'PINGR Team actions.pdf';
    rows = rows.map((v) => {
      const cols = [];
      cols.push(v[1]);
      cols.push(v[2]);
      cols.push(v[3]);
      cols.push($(v[4]).html());
      cols.push($(v[5]).html());
      const str = $(v[6])
        .find('a')
        .map((i, e) => $(e).text())
        .get()
        .join(', ');
      cols.push(str);
      cols.push(
        $(v[7]).find('input[type="checkbox"]').val() === '1' ? 'Yes' : 'No'
      );
      return cols;
    });
    pdfOpts.columnStyles[5] = { columnWidth: 70 };
    pdfOpts.columnStyles[6] = { columnWidth: 70 };
  }
  // Only pt supported (not mm or in)
  const doc = new JSPDF('l', 'pt');
  doc.autoTable(columns, rows, pdfOpts);
  doc.save(filename);
};

const dateToString = (date) => {
  const d = date ? new Date(date) : new Date();
  if (d.getTime && !Number.isNaN(d.getTime())) {
    return d.toLocaleDateString('en-GB');
  }
  return '';
};

const actionToObj = (action) => {
  const actionObj = action;
  if (action.patientId) {
    actionObj.type = 'patient';
    actionObj.patientNhsNo = data.getNHS(
      state.selectedPractice._id,
      action.patientId
    );
  } else {
    actionObj.type = 'team';
  }
  actionObj.actionTextScreen = action.actionText.replace(
    /\[([^\].]*)(\.*)\]/g,
    ''
  );
  actionObj.createdBy = action.createdUser
    ? action.createdUser.fullname
    : 'PINGR';
  actionObj.agreedBy = action.agreedUser ? action.agreedUser.fullname : '';
  actionObj.when = dateToString(action.createdDate || action.agreedDate);
  actionObj.assignedTo = action.assignedUser
    ? action.assignedUser.fullname
    : '<unassigned>';
  actionObj.assignedToId = action.assignedUser ? action.assignedUser._id : null;
  actionObj.assignedDate = dateToString(action.assignedDate);
  actionObj.doneBy = action.doneUser ? action.doneUser.fullname : '';
  actionObj.doneDate = dateToString(action.doneDate);
  actionObj.indicatorList = action.indicatorList
    ? action.indicatorList.map((i) => {
        const indicatorName = data.getDisplayTextFromIndicatorId(i);
        let url;
        if (actionObj.patientId) {
          url = `#patient/${actionObj.patientId}/${i.replace(/\./g, '/')}`;
        } else {
          url = `#indicators/${i.replace(/\./g, '/')}`;
        }
        return `<a href="${url}" class="nav-after-modal-hidden" data-dismiss="modal">${indicatorName}</a>`;
      })
    : undefined;
  return actionObj;
};

let actionObject = {};
let practiceUsers;
const indicators = {};
let patientActions;
let teamActions;
let patientSearchCreated;

const al = {
  show(panel, isAppend) {
    data.getAllIndicatorData(
      state.selectedPractice._id,
      false,
      (indicatorData) => {
        indicators.processIndicators = indicatorData
          .filter((v) => v.type === 'process')
          .map((v) => ({ id: v.id, name: v.name }));
        indicators.outcomeIndicators = indicatorData
          .filter((v) => v.type === 'outcome')
          .map((v) => ({ id: v.id, name: v.name }));
        data.getActiveUsersForPractice(
          state.selectedPractice._id,
          (err, users) => {
            practiceUsers = users;
            const html = actionListWrapperTmpl({
              practiceUsers,
              currentUserName: lookup.userName,
            });
            if (isAppend) panel.append(html);
            else {
              panel.html(html);
            }
            al.load(() => {
              al.process(al.populate);
            });
          }
        );
      }
    );
  },

  load(done) {
    data.getAllAgreedWithActions(state.selectedPractice._id, (err, actions) => {
      actionObject = actions;
      if (done && typeof done === 'function') {
        return done();
      }
      return false;
    });
  },

  process(done) {
    patientActions = {};
    teamActions = {};
    patientSearchCreated = false;

    if (
      actionObject.patient &&
      (!data.patLookup || !data.patLookup[state.selectedPractice._id])
    ) {
      // pat lookup not loaded so let's wait
      return setTimeout(() => {
        al.process(done);
      }, 1000);
    }
    if (actionObject.patient) {
      Object.keys(actionObject.patient).forEach((v) => {
        actionObject.patient[v].actions.forEach((vv) => {
          const actionItem = actionToObj(vv);
          patientActions[`${actionItem.actionTextId}/${actionItem.patientId}`] =
            actionItem;
        });
        actionObject.patient[v].userDefinedActions.forEach((vv) => {
          const actionItem = actionToObj(vv);
          patientActions[`${actionItem.actionTextId}/${actionItem.patientId}`] =
            actionItem;
        });
      });
    }
    if (actionObject.team) {
      if (actionObject.team.actions) {
        actionObject.team.actions.forEach((v) => {
          const actionItem = actionToObj(v);
          teamActions[actionItem.actionTextId] = actionItem;
        });
      }
      if (actionObject.team.userDefinedActions) {
        actionObject.team.userDefinedActions.forEach((v) => {
          const actionItem = actionToObj(v);
          teamActions[actionItem.actionTextId] = actionItem;
        });
      }
    }

    if (done && typeof done === 'function') {
      return done();
    }
    return false;
  },

  populate() {
    const tmpl = actionListTemplate;
    const patientActionsData = {
      actions: Object.keys(patientActions).map((key) => patientActions[key]),
      actionType: 'patient',
    };
    if (patientActionsData.actions.length === 0)
      patientActionsData.noSuggestions = true;
    const teamActionsData = {
      actions: Object.keys(teamActions).map((key) => teamActions[key]),
      actionType: 'team',
    };
    if (teamActionsData.actions.length === 0)
      teamActionsData.noSuggestions = true;
    $('#patient-actions-table-wrapper').html(tmpl(patientActionsData));
    $('#team-actions-table-wrapper').html(tmpl(teamActionsData));
    $('#action-plans-modal-wrapper').html(
      actionListModalsTemplate({
        practiceUsers,
        indicators,
        currentUserName: lookup.userName,
      })
    );

    const formatTableBodyForExport = (d, row, column, node) => {
      const $n = $(node);
      if ($n.hasClass('assignToCell')) {
        return $n.find('.action-assigned-to-name').text();
      }
      if ($n.hasClass('actionTextCell')) {
        return $n.find('span.action-text').text();
      }
      if ($n.hasClass('actionCompletedCell')) {
        return $n.find('input[type="checkbox"]').val() === '1' ? 'Yes' : 'No';
      }
      if ($n.hasClass('actionIndicatorsCell')) {
        return $n
          .find('a')
          .map((i, e) => $(e).text())
          .get()
          .join(', ');
      }
      return $n.text();
    };

    const baseTableOptions = () => ({
      searching: true,
      paging: false,
      stateSave: false,
      dom: 'rt<"clear">',
      scrollY: 'calc(100vh - 310px)',
      language: {
        emptyTable: 'There are currently no actions in this plan',
        zeroRecords: 'No matching actions found',
      },
      scrollCollapse: true,
      columnDefs: [
        { type: 'date-uk', targets: 3 },
        { type: 'dom-checkbox', targets: 7 },
      ],
      order: [[3, 'asc']],
      buttons: [
        {
          text: 'Save as PDF',
          action(e, dt) {
            saveTableAsPDF(dt);
          },
        },
        {
          extend: 'excel',
          text: 'Save as Excel',
          exportOptions: {
            columns: ['0:visible', 1, 2, 3, 4, 5, 6, 7],
            format: { body: formatTableBodyForExport },
          },
        },
        {
          extend: 'copy',
          text: 'Copy to clipboard',
          exportOptions: {
            columns: ['0:visible', 1, 2, 3, 4, 5, 6, 7],
            format: { body: formatTableBodyForExport },
          },
        },
      ],
    });

    const patientTableOptions = baseTableOptions();
    patientTableOptions.buttons[1].title = 'PINGR Patient actions';
    const patientActionsTable = $('#suggested-patient-actions-table').DataTable(
      patientTableOptions
    );

    const teamTableOptions = baseTableOptions();
    teamTableOptions.buttons[1].title = 'PINGR Team actions';
    teamTableOptions.columnDefs.push({ targets: 0, visible: false });
    const teamActionsTable = $('#suggested-team-actions-table').DataTable(
      teamTableOptions
    );

    const assginedToColIndex = 4;

    // redraw tables after switching tab to ensure headers/columns line up
    $('a[data-toggle="tab"]').on('shown.bs.tab', (e) => {
      if ($(e.target).data('content') === 'patientActionsList') {
        patientActionsTable.columns.adjust().draw();
      }
      if ($(e.target).data('content') === 'teamActionsList') {
        teamActionsTable.columns.adjust().draw();
      }
      state.setActionPlanState('tab', $(e.target).parent().prop('id'));
    });

    const showPatientActionsAssignedTo = (name) => {
      patientActionsTable
        .column(assginedToColIndex)
        .search(name === 'everyone' ? '' : name)
        .draw(true);
      state.setActionPlanState('whoseActionsPatient', name);
    };
    const showTeamActionsAssignedTo = (name) => {
      teamActionsTable
        .column(assginedToColIndex)
        .search(name === 'everyone' ? '' : name)
        .draw(true);
      state.setActionPlanState('whoseActionsTeam', name);
    };
    $('#patient-actions-controls').find('.whose-actions-select').selectpicker();
    $('#patient-actions-controls')
      .find('.whose-actions-select')
      .on('changed.bs.select', (e) => {
        showPatientActionsAssignedTo($(e.target).val());
      });
    $('#team-actions-controls').find('.whose-actions-select').selectpicker();
    $('#team-actions-controls')
      .find('.whose-actions-select')
      .on('changed.bs.select', (e) => {
        showTeamActionsAssignedTo($(e.target).val());
      });
    // shortcuts to 'mine' and 'everyone's' assigned actions
    const updateWhoseActionsPatient = (who) => {
      $('#patient-actions-controls')
        .find('.whose-actions-select')
        .selectpicker('val', who);
      showPatientActionsAssignedTo(who);
    };
    const updateWhoseActionsTeam = (who) => {
      $('#team-actions-controls')
        .find('.whose-actions-select')
        .selectpicker('val', who);
      showTeamActionsAssignedTo(who);
    };
    $('#patient-actions-controls').on(
      'click',
      'button.view-all-actions-btn',
      (e) => {
        updateWhoseActionsPatient('everyone');
        $(e.target).blur();
      }
    );
    $('#patient-actions-controls').on(
      'click',
      'button.view-my-actions-btn',
      (e) => {
        updateWhoseActionsPatient(lookup.userName);
        $(e.target).blur();
      }
    );
    $('#team-actions-controls').on(
      'click',
      'button.view-all-actions-btn',
      (e) => {
        updateWhoseActionsTeam('everyone');
        $(e.target).blur();
      }
    );
    $('#team-actions-controls').on(
      'click',
      'button.view-my-actions-btn',
      (e) => {
        updateWhoseActionsTeam(lookup.userName);
        $(e.target).blur();
      }
    );

    // general search box
    $('#patient-actions-controls').on('keyup', 'input.search-actions', (e) => {
      patientActionsTable.search($(e.target).val()).draw();
      state.setActionPlanState('searchStringPatient', $(e.target).val());
    });
    $('#team-actions-controls').on('keyup', 'input.search-actions', (e) => {
      teamActionsTable.search($(e.target).val()).draw();
      state.setActionPlanState('searchStringTeam', $(e.target).val());
    });

    // show/hide completed actions
    $('#patient-actions-controls').on(
      'click',
      'button.hide-done-actions-btn',
      (e) => {
        const $button = $(e.target);
        $button.toggleClass('hideDone');
        $button.text(
          `${$button.hasClass('hideDone') ? 'Show' : 'Hide'} completed actions`
        );
        patientActionsTable.draw();
        state.setActionPlanState(
          'showDonePatient',
          !$button.hasClass('hideDone')
        );
        $(e.target).blur();
      }
    );
    $('#team-actions-controls').on(
      'click',
      'button.hide-done-actions-btn',
      (e) => {
        const $button = $(e.target);
        $button.toggleClass('hideDone');
        $button.text(
          `${$button.hasClass('hideDone') ? 'Show' : 'Hide'} completed actions`
        );
        teamActionsTable.draw();
        state.setActionPlanState('showDoneTeam', !$button.hasClass('hideDone'));
        $(e.target).blur();
      }
    );

    // export action plan
    $('#patient-actions-controls').on('click', '.save-as-pdf-btn', (e) => {
      e.preventDefault();
      saveTableAsPDF(patientActionsTable);
      $(e.target).blur();
    });
    $('#patient-actions-controls').on(
      'click',
      '.copy-to-clipboard-btn',
      (e) => {
        e.preventDefault();
        patientActionsTable.button('.buttons-copy').trigger();
        $(e.target).blur();
      }
    );
    $('#patient-actions-controls').on('click', '.save-as-excel-btn', (e) => {
      e.preventDefault();
      patientActionsTable.button('.buttons-excel').trigger();
      $(e.target).blur();
    });
    $('#team-actions-controls').on('click', '.save-as-pdf-btn', (e) => {
      e.preventDefault();
      saveTableAsPDF(teamActionsTable);
      $(e.target).blur();
    });
    $('#team-actions-controls').on('click', '.copy-to-clipboard-btn', (e) => {
      e.preventDefault();
      teamActionsTable.button('.buttons-copy').trigger();
      $(e.target).blur();
    });
    $('#team-actions-controls').on('click', '.save-as-excel-btn', (e) => {
      e.preventDefault();
      teamActionsTable.button('.buttons-excel').trigger();
      $(e.target).blur();
    });

    const actionObjForRow = ($tr) => {
      const actionType = $tr.data('action_type');
      let action;
      if (actionType === 'patient') {
        action =
          patientActions[
            `${$tr.data('action_text_id')}/${$tr.data('patient_id')}`
          ];
      } else {
        action = teamActions[$tr.data('action_text_id')];
      }
      return action;
    };

    // Modals

    $('#actionPlanTablesContainer').on('click', '.reassign-action', (e) => {
      e.preventDefault();
      const $td = $(e.target).parents('td:first');
      const $tr = $(e.target).parents('tr:first');
      const actionObj = actionObjForRow($tr);
      const table = $tr.parents('table:first').DataTable();
      const assignedToUserId = $tr.data('assigned_to_id');
      const actionType = $tr.data('action_type');
      $('#assignUserModal')
        .find('.assign-action-text')
        .text($tr.data('action_text_screen'));
      if (actionType === 'patient') {
        $('#assignUserModal')
          .find('.assign-action-team-or-patient')
          .text('Assign the patient action:');
        $('#assignUserModal')
          .find('.assign-action-nhs')
          .text(`NHS no. ${$tr.data('nhs')}`)
          .show();
      } else {
        $('#assignUserModal')
          .find('.assign-action-team-or-patient')
          .text('Assign the team action:');
        $('#assignUserModal').find('.assign-action-nhs').hide();
      }
      $('#assignUserModal').find('select.practice-user-list').selectpicker();
      $('#assignUserModal')
        .find('select.practice-user-list')
        .selectpicker('val', assignedToUserId || '');
      $('#assignUserModal')
        .off('click', '.save-action')
        .on('click', '.save-action', () => {
          const selectedUserId = $('#assignUserModal')
            .find('select.practice-user-list')
            .val();
          if (selectedUserId && selectedUserId !== assignedToUserId) {
            const actionTextId = $tr.data('action_text_id');
            const patientId = $tr.data('patient_id');
            const selectedUserName = $(
              '#assignUserModal select option:selected'
            )
              .text()
              .replace(/ \(me\)/, '');
            log.assignActionToUser(
              state.selectedPractice._id,
              { actionType, actionTextId, patientId },
              selectedUserId,
              (err, act) => {
                if (act.assignedUser === selectedUserId) {
                  actionObj.assignedTo = selectedUserName;
                  actionObj.assignedDate = dateToString(act.assignedDate);
                  $tr.data('assigned_to_id', selectedUserId);
                  $td.find('a').text(selectedUserName);
                  $td
                    .find('span.action-assigned-to-name')
                    .text(selectedUserName);
                  table.cell($td).invalidate().draw(false);
                }
              }
            );
          }
          $('#assignUserModal').modal('hide');
        })
        .modal();
    });

    $('#actionPlanTablesContainer').on('click', '.view-action-button', (e) => {
      const $tr = $(e.target).parents('tr:first');
      const actionType = $tr.data('action_type');
      const action = actionObjForRow($tr);
      if (actionType === 'patient') {
        $('#actionInfoPatientCell').html(
          `<a href="#patient/${$tr.data(
            'patient_id'
          )}" class="nav-after-modal-hidden" data-dismiss="modal">${$tr.data(
            'nhs'
          )}</a>`
        );
        $('#actionInfoPatientRow').show();
      } else {
        $('#actionInfoPatientRow').hide();
      }
      $('#actionInfoActionCell').html(action.actionTextScreen);
      if (action.indicatorList && action.indicatorList.length > 0) {
        $('#actionInfoIndicatorsCell').html(action.indicatorList.join('<br>'));
        if (action.supportingText) {
          $('#actionInfoIndicatorsCell').append(
            `<div style="border-top:1px solid #eee;margin-top:5px;padding-top:5px;font-size:90%;max-height:200px;overflow-y:hidden;overflow-y:scroll">${action.supportingText}</div>`
          );
        }
        $('#actionInfoIndicatorsRow').show();
      } else {
        $('#actionInfoIndicatorsRow').hide();
      }
      if (action.agreedBy && action.agreedBy !== '') {
        $('#actionInfoAgreedByCell').html(
          `${action.agreedBy} on ${action.when}`
        );
        $('#actionInfoAgreedByRow').show();
      } else {
        $('#actionInfoAgreedByRow').hide();
      }
      if (action.createdBy && action.createdBy !== 'PINGR') {
        $('#actionInfoCreatedByCell').html(
          `${action.createdBy} on ${action.when}`
        );
        $('#actionInfoCreatedByRow').show();
      } else {
        $('#actionInfoCreatedByRow').hide();
      }
      if (action.assignedTo) {
        $('#actionInfoAssignedToCell').html(
          `${action.assignedTo} on ${action.assignedDate}`
        );
        $('#actionInfoAssignedToRow').show();
      } else {
        $('#actionInfoAssignedToRow').hide();
      }
      if (action.done === true) {
        $('#actionInfoCompletedDateCell').html(
          `${action.doneBy} on ${action.doneDate}`
        );
        $('#actionInfoCompletedRow').show();
      } else {
        $('#actionInfoCompletedRow').hide();
      }
      if (action.history && action.history.length > 1) {
        let historyString = '<ul style="font-size:90%;">';
        action.history.slice(1).forEach((h) => {
          historyString += `<li>${h.who} ${h.what} this action on `;
          historyString += dateToString(h.when);
          if (h.why) {
            historyString += `, because: <em>${h.why}</em>`;
          }
          historyString += '</li>';
        });
        historyString += '</ul>';
        $('#actionInfoHistoryCell').html(historyString);
        $('#actionInfoHistoryRow').show();
      } else {
        $('#actionInfoHistoryRow').hide();
      }
      $('#viewActionDetailsModal')
        .off('click', '.nav-after-modal-hidden')
        .on('click', '.nav-after-modal-hidden', (ev) => {
          $('#viewActionDetailsModal').data(
            'goto_url_after_hide',
            $(ev.target).prop('href')
          );
        })
        .off('hidden.bs.modal')
        .on('hidden.bs.modal', () => {
          if ($('#viewActionDetailsModal').data('goto_url_after_hide')) {
            window.location = $('#viewActionDetailsModal').data(
              'goto_url_after_hide'
            );
          }
        })
        .modal();
    });

    $('#actionPlanTablesContainer').on('click', 'input.big-checkbox', (e) => {
      const $cb = $(e.target);
      const $tr = $cb.parents('tr:first');
      const actionObj = actionObjForRow($tr);
      const table = $tr.parents('table:first').DataTable();
      const actionType = $tr.data('action_type');
      const actionTextId = $tr.data('action_text_id');
      const patientId = $tr.data('patient_id');
      const newDoneState = !$tr.data('action_done');
      log.updateActionDoneState(
        state.selectedPractice._id,
        { actionType, actionTextId, patientId },
        newDoneState,
        (err, act) => {
          if (act.done) {
            $tr.addClass('action-completed');
            $tr.data('action_done', true);
            $cb.val(1);
            actionObj.done = act.done;
            actionObj.doneBy = lookup.userName;
            actionObj.doneDate = dateToString(act.doneDate);
          } else {
            $tr.removeClass('action-completed');
            $tr.data('action_done', false);
            $cb.val(0);
            actionObj.done = act.done;
            actionObj.doneBy = null;
            actionObj.doneDate = null;
          }
          table.row($tr).invalidate();
          table.draw(false);
        }
      );
    });

    $('#actionPlanTablesContainer').on(
      'click',
      '.delete-action-button',
      (e) => {
        const $tr = $(e.target).parents('tr:first');
        const table = $tr.parents('table:first').DataTable();
        $('#modal-delete-item').html($tr.data('action_text_screen'));
        $('#deletePlan')
          .off('hidden.bs.modal')
          .on('hidden.bs.modal', () => {
            table.draw();
          })
          .off('click', '.delete-plan')
          .on('click', '.delete-plan', () => {
            const actionTextId = $tr.data('action_text_id');
            const actionType = $tr.data('action_type');
            if (actionType === 'patient') {
              log.deleteUserDefinedPatientAction(
                $tr.data('patient_id'),
                actionTextId,
                () => {
                  table.row($tr).remove();
                }
              );
            } else if (actionType === 'team') {
              log.deleteUserDefinedTeamAction(
                state.selectedPractice._id,
                actionTextId,
                () => {
                  table.row($tr).remove();
                }
              );
            }
            $('#deletePlan').modal('hide');
          })
          .modal();
      }
    );

    $('#actionPlanTablesContainer').on('click', '.edit-action-button', (e) => {
      const $tr = $(e.target).parents('tr:first');
      const actionObj = actionObjForRow($tr);
      const table = $tr.parents('table:first').DataTable();
      const oldActionTextId = $tr.data('action_text_id');
      $('#editActionPlanItem').val($tr.data('action_text'));
      $('#editPlan')
        .off('shown.bs.modal')
        .on('shown.bs.modal', () => {
          $('#editActionPlanItem').focus();
        })
        .off('click', '.save-plan')
        .on('click', '.save-plan', () => {
          const newActionText = $('#editActionPlanItem').val();
          const newActionTextId = newActionText
            .toLowerCase()
            .replace(/[^a-z0-9]/g, '');
          if (newActionTextId !== oldActionTextId) {
            const newAction = {
              actionText: newActionText,
              actionTextId: newActionTextId,
            };
            const actionType = $tr.data('action_type');
            const editCallback = () => {
              actionObj.actionTextId = newActionTextId;
              actionObj.actionText = newActionText;
              if (actionType === 'patient') {
                patientActions[
                  `${actionObj.actionTextId}/${actionObj.patientId}`
                ] = actionObj;
                patientActions[`${oldActionTextId}/${actionObj.patientId}`] =
                  undefined;
              } else {
                teamActions[actionObj.actionTextId] = actionObj;
                teamActions[oldActionTextId] = undefined;
              }
              $tr.data('action_text_id', newActionTextId);
              $tr.data('action_text', newActionText);
              const $td = $tr.find('td.actionTextCell:first');
              $td.find('span.action-text').html(newActionText);
              table.cell($td).invalidate().draw(false);
            };
            if (actionType === 'patient') {
              log.updateUserDefinedPatientAction(
                $tr.data('patient_id'),
                oldActionTextId,
                newAction,
                editCallback
              );
            } else if (actionType === 'team') {
              log.updateUserDefinedTeamAction(
                state.selectedPractice._id,
                oldActionTextId,
                newAction,
                editCallback
              );
            }
          }
          $('#editPlan').modal('hide');
        })
        .modal();
    });

    $('#actionPlanTablesContainer').on(
      'click',
      '.remove-action-button',
      (e) => {
        const $tr = $(e.target).parents('tr:first');
        const table = $tr.parents('table:first').DataTable();
        $('#removeOrDisagreeModal')
          .find('.action-text')
          .html($tr.data('action_text_screen'));
        $('#removeOrDisagreeModal')
          .find('input[name="reason"]:first')
          .prop('checked', true);
        $('#removeOrDisagreeModal').find('textarea.disagree-why-text').val('');
        $('#removeOrDisagreeModal')
          .find('button.remove-plan')
          .text('Remove action');
        $('#removeOrDisagreeModal')
          .find('input[name="reason"]')
          .off('change')
          .on('change', () => {
            const what = $('#removeOrDisagreeModal')
              .find('input[name="reason"]:checked')
              .val();
            $('#removeOrDisagreeModal')
              .find('button.remove-plan')
              .text(
                what === 'remove' ? 'Remove action' : 'Disagree with action'
              );
          });
        $('#removeOrDisagreeModal')
          .off('hidden.bs.modal')
          .on('hidden.bs.modal', () => {
            table.draw();
          })
          .off('click', '.remove-plan')
          .on('click', '.remove-plan', () => {
            const what = $('#removeOrDisagreeModal')
              .find('input[name="reason"]:checked')
              .val();
            const reason = $('#removeOrDisagreeModal')
              .find('textarea.disagree-why-text')
              .val();
            // if user disagrees then make sure they've given a reason!
            // if (what === 'disagree' && !reason) {
            //   $('#removeOrDisagreeModal').find('textarea.disagree-why-text').focus();
            //   return;
            // }
            // update action
            const updatedAction = {
              actionTextId: $tr.data('action_text_id'),
              agree: null,
              agreedUser: null,
              agreedDate: null,
              assignedUser: null,
              assignedDate: null,
              rejectedUser: null,
              rejectedDate: null,
              rejectedReasonText: null,
            };
            if (what === 'disagree') {
              const rejectedDate = new Date();
              const oldAction = actionObjForRow($tr);
              updatedAction.agree = false;
              updatedAction.rejectedUser = lookup.userID;
              updatedAction.rejectedDate = rejectedDate;
              updatedAction.rejectedReasonText = reason;
              updatedAction.actionCat = oldAction.actionCat;
              updatedAction.history = oldAction.history || [];
              updatedAction.history.unshift({
                who: lookup.userName,
                what: 'disagreed with',
                when: rejectedDate,
                why: reason,
              });
            }
            const actionType = $tr.data('action_type');
            const updateCallback = () => {
              table.row($tr).remove();
              table.draw(false);
            };
            if (actionType === 'patient') {
              log.updateIndividualAction(
                state.selectedPractice._id,
                $tr.data('patient_id'),
                updatedAction,
                updateCallback
              );
            } else if (actionType === 'team') {
              log.updateTeamAction(
                state.selectedPractice._id,
                '',
                updatedAction,
                updateCallback
              );
            }
            $('#removeOrDisagreeModal').modal('hide');
          })
          .modal();
      }
    );

    $('#patient-actions-controls').on(
      'click',
      '.add-userdefined-action-btn',
      (e) => {
        const table = patientActionsTable;
        const $indicatorList = $('#newUserDefinedPatientActionModal').find(
          '.relevant-indicators:first'
        );
        $('#newUserDefinedPatientActionModal')
          .find('textarea.action-text')
          .val('');
        $('#newUserDefinedPatientActionModal')
          .find('input.selected-patient-id')
          .val('');
        $('#newUserDefinedPatientActionModal')
          .find('.patient-specs')
          .find('.nhs')
          .text('No patient selected');
        $('#newUserDefinedPatientActionModal')
          .find('.patient-specs')
          .find('.sexAndAge')
          .text('');
        $('#newUserDefinedPatientActionModal').find('.patient-specs').hide();
        $('#newUserDefinedPatientActionModal').find('input.typeahead').val('');
        $('#newUserDefinedPatientActionModal')
          .find('.relevant-indicators-section')
          .hide();
        $('#newUserDefinedPatientActionModal')
          .find('select.practice-user-list')
          .selectpicker();
        $('#newUserDefinedPatientActionModal')
          .find('select.practice-user-list')
          .selectpicker('val', lookup.userID);
        if (!patientSearchCreated) {
          const patientSearchHander = ($e, nhsNumberObject) => {
            $indicatorList.empty();
            $('#newUserDefinedPatientActionModal')
              .find('input.selected-patient-id:first')
              .val(nhsNumberObject.id);
            data.getPatientData(nhsNumberObject.id, (patientData) => {
              $('#newUserDefinedPatientActionModal')
                .find('.patient-specs')
                .find('.nhs')
                .text(patientData.characteristics.nhs);
              $('#newUserDefinedPatientActionModal')
                .find('.patient-specs')
                .find('.sexAndAge')
                .text(
                  `${
                    patientData.characteristics.sex === 'M' ? 'Male' : 'Female'
                  }, ${patientData.characteristics.age}`
                );
              $('#newUserDefinedPatientActionModal')
                .find('.patient-specs')
                .show();
              const standards = patientData.standards
                .filter(
                  (v) =>
                    v.targetMet === false &&
                    !data.isExcluded(nhsNumberObject.id, v.indicatorId)
                )
                .map((v) => ({ id: v.indicatorId, name: v.display }));
              if (standards.length > 0) {
                standards.forEach((standard) => {
                  $indicatorList.append(`<div class="checkbox">
                  <label>
                    <input name='indicators' type="checkbox" value='${standard.id}'> ${standard.name}
                  </label>
                </div>`);
                });
                $('#newUserDefinedPatientActionModal')
                  .find('.relevant-indicators-section')
                  .show();
              } else {
                $('#newUserDefinedPatientActionModal')
                  .find('.relevant-indicators-section')
                  .hide();
              }
            });
          };
          const patientSearchChangedHander = ($e, val) => {
            if (!val) {
              $indicatorList.empty();
              $('#newUserDefinedPatientActionModal')
                .find('input.selected-patient-id')
                .val('');
              $('#newUserDefinedPatientActionModal')
                .find('.patient-specs')
                .find('.nhs')
                .text('No patient selected');
              $('#newUserDefinedPatientActionModal')
                .find('.patient-specs')
                .find('.sexAndAge')
                .text('');
              $('#newUserDefinedPatientActionModal')
                .find('.patient-specs')
                .hide();
            }
          };
          patientSearch.showAsLightweightWidget(
            '#newUserDefinedPatientActionModal',
            patientSearchHander,
            patientSearchChangedHander
          );
          patientSearchCreated = true;
        }
        $('#newUserDefinedPatientActionModal')
          .off('click', '.create-plan')
          .on('click', '.create-plan', () => {
            // Make sure they've selected a patient
            const patientId = $('#newUserDefinedPatientActionModal')
              .find('input.selected-patient-id:first')
              .val();
            if (!patientId) {
              $('#newUserDefinedPatientActionModal')
                .find('input.typeahead:first')
                .focus();
              $('#newUserDefinedPatientActionModal')
                .find('.patient-specs')
                .show();
              return;
            }
            // make sure they've entered an action
            const actionText = $('#newUserDefinedPatientActionModal')
              .find('textarea.action-text:first')
              .val();
            if (!actionText) {
              $('#newUserDefinedPatientActionModal')
                .find('textarea.action-text:first')
                .focus();
              return;
            }
            // get any selected indicators
            const indicatorList = $('#newUserDefinedPatientActionModal')
              .find('input[name="indicators"]:checked')
              .map((i, elm) => $(elm).val())
              .get();
            const assignedUserId = $('#newUserDefinedPatientActionModal')
              .find('select.practice-user-list')
              .val();
            // create new action
            log.recordIndividualPlan(
              actionText,
              state.selectedPractice._id,
              patientId,
              indicatorList,
              assignedUserId,
              (err, a) => {
                if (a) {
                  const actionObj = actionToObj(a);
                  // add new action to the table
                  const rowHtml = actionListRowTemplate({ action: actionObj });
                  $('#suggested-patient-actions-table tr:last').after(rowHtml);
                  table.row.add($('#suggested-patient-actions-table tr:last'));
                  // add new action to the lookup table
                  patientActions[
                    `${actionObj.actionTextId}/${actionObj.patientId}`
                  ] = actionObj;
                  table.draw();
                }
              }
            );
            $('#newUserDefinedPatientActionModal').modal('hide');
          })
          .modal();
        $(e.target).blur();
      }
    );

    $('#team-actions-controls').on(
      'click',
      '.add-userdefined-action-btn',
      (e) => {
        const table = teamActionsTable;
        $('#newUserDefinedTeamActionModal')
          .find('textarea.action-text')
          .val('');
        $('#newUserDefinedTeamActionModal')
          .find('select.relevant-indicator')
          .selectpicker();
        $('#newUserDefinedTeamActionModal')
          .find('select.practice-user-list')
          .selectpicker();
        $('#newUserDefinedTeamActionModal')
          .find('select.relevant-indicator')
          .selectpicker('val', '');
        $('#newUserDefinedTeamActionModal')
          .find('select.practice-user-list')
          .selectpicker('val', lookup.userID);
        $('#newUserDefinedTeamActionModal')
          .off('click', '.create-plan')
          .on('click', '.create-plan', () => {
            const actionText = $('#newUserDefinedTeamActionModal')
              .find('textarea.action-text:first')
              .val();
            const indicatorId = $('#newUserDefinedTeamActionModal')
              .find('select.relevant-indicator')
              .val();
            const assignedUserId = $('#newUserDefinedTeamActionModal')
              .find('select.practice-user-list')
              .val();
            // make sure they've entered an action
            if (!actionText) {
              $('#newUserDefinedTeamActionModal')
                .find('textarea.action-text:first')
                .focus();
              return;
            }
            // create new action
            log.recordTeamPlan(
              state.selectedPractice._id,
              actionText,
              indicatorId,
              assignedUserId,
              (err, a) => {
                if (a) {
                  const actionObj = actionToObj(a);
                  // add new action to the table
                  const rowHtml = actionListRowTemplate({ action: actionObj });
                  $('#suggested-team-actions-table tr:last').after(rowHtml);
                  table.row.add($('#suggested-team-actions-table tr:last'));
                  // add new action to the lookup table
                  teamActions[actionObj.actionTextId] = actionObj;
                  table.draw();
                }
              }
            );
            $('#newUserDefinedTeamActionModal').modal('hide');
          })
          .modal();
        $(e.target).blur();
      }
    );

    base.wireUpTooltips();

    // Restore last state
    // active tab
    if (state.actionPlanState('tab')) {
      $(`#${state.actionPlanState('tab')}`)
        .find('a:first')
        .tab('show');
    }
    // whose assigned action are visible
    updateWhoseActionsPatient(
      state.actionPlanState('whoseActionsPatient') || lookup.userName
    );
    updateWhoseActionsTeam(
      state.actionPlanState('whoseActionsTeam') || lookup.userName
    );
    // show/hide completed actions
    if (state.actionPlanState('showDonePatient')) {
      $('#patient-actions-controls')
        .find('button.hide-done-actions-btn')
        .click();
    }
    if (state.actionPlanState('showDoneTeam')) {
      $('#team-actions-controls').find('button.hide-done-actions-btn').click();
    }
    // search
    if (state.actionPlanState('searchStringPatient')) {
      $('#patient-actions-controls')
        .find('input.search-actions')
        .val(state.actionPlanState('searchStringPatient'))
        .keyup();
    }
    if (state.actionPlanState('searchStringTeam')) {
      $('#team-actions-controls')
        .find('input.search-actions')
        .val(state.actionPlanState('searchStringTeam'))
        .keyup();
    }
    document.getElementById('main').style.display = 'block';
    base.hideLoading();
  },
};

module.exports = al;
