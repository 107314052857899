.modal.fade(tabindex='-1', role='dialog', aria-labelledby='modalLabel', aria-hidden='true')
  .modal-dialog
    .modal-content
      .modal-header
        button.close(type='button', data-dismiss='modal', aria-label='Close')
          span(aria-hidden='true') ×
        h4.modal-title Exclude a patient
      .modal-body
        label 
          | You are about to exclude patient  
          = nhs 
          |  from: 
        p
          span.text-warning(style='font-style:italic')= indicator
        form(style='padding-top:5px')
          div.form-group
            label
              | That's fair enough! 
            p  Please can you tell us why?
          div.form-group
            .radio
              label
                input(type="radio",name="reason",value="RIP", required)
                | RIP
            .radio
              label
                input(type="radio",name="reason",value="not registered", required)
                | No longer registered
            .radio
              label
                input(type="radio",name="reason",value="other", required)
                | Other
          div.form-group
            textarea#modal-why-text.form-control(rows='3', style='resize:none;', placeholder='Provide more information here...')
          .form-group
            .checkbox.disabled
              label
                input(type="checkbox",name="includeAfter12Months",value="1" disabled)
                | Automatically re-include patient after 12 months
          button.btn.btn-primary.save-plan(type='submit', style='margin-right:5px') Save
          button.btn.btn-default(type='button', data-dismiss='modal') Cancel
      .modal-footer
