include mixins/action-plan-item

include mixins/personal-action-plan
if hasSuggestions
  .personal-action-table-wrapper
    table.table(style='margin-bottom:5px')
      thead
        tr
          th Suggested by you
          th(style='width: 175px')
      tbody
        each suggestion in suggestions
          +actionPlanItem(suggestion)
