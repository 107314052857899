const notify = require('./notify');
const data = require('./data');
const lookup = require('./lookup');
const uuidv4 = require('uuid/v4');

let eventFailCount = 0;
let pageId = '';

const log = {
  reason: {},

  navigatePage(toUrl, practiceId, dataProp) {
    pageId = uuidv4();
    log.event('navigate', toUrl, practiceId, dataProp);
  },

  navigateTab(toUrl, practiceId, dataProp) {
    log.event('navigate-tab', toUrl, practiceId, dataProp);
  },

  event(type, url, practiceId, dataProp, xpath) {
    const dataToSend = {
      event: { type, url, pageId, data: dataProp, practiceId },
    };
    if (lookup.tests && Object.keys(lookup.tests).length > 0)
      dataToSend.event.tests = lookup.tests;
    if (xpath && xpath.length > 0) dataToSend.event.xpath = xpath;
    console.log(`EVENT: ${JSON.stringify(dataToSend)}`);
  },

  excludePatientFromIndicatorInUI(
    practiceId,
    patientId,
    indicatorId,
    reason,
    freetext,
    includeAfter12
  ) {
    const when = Date.now();
    let includeAfterDate = null;
    if (includeAfter12) {
      includeAfterDate = new Date(when);
      includeAfterDate.setFullYear(includeAfterDate.getFullYear() + 1);
    }
    const obj = {
      practiceId,
      patientId,
      indicatorId,
      reason,
      freetext,
      who: lookup.userName,
      when,
      includeAfterDate,
    };
    if (!data.excludedPatients[patientId]) {
      data.excludedPatients[patientId] = [obj];
    } else {
      data.excludedPatients[patientId].push(obj);
    }
    if (!data.excludedPatientsByIndicator[indicatorId]) {
      data.excludedPatientsByIndicator[indicatorId] = [parseInt(patientId, 10)];
    } else {
      data.excludedPatientsByIndicator[indicatorId].push(
        parseInt(patientId, 10)
      );
    }
  },

  removeAllPatientExclusionsInUI(practiceId, patientId) {
    if (data.excludedPatients[patientId]) {
      data.excludedPatients[patientId] = undefined;
    }
    Object.keys(data.excludedPatientsByIndicator).forEach((indicatorId) => {
      if (data.excludedPatientsByIndicator[indicatorId]) {
        data.excludedPatientsByIndicator[indicatorId] =
          data.excludedPatientsByIndicator[indicatorId].filter(
            (v) => v !== parseInt(patientId, 10)
          );
      }
    });
  },

  excludePatientFromIndicator(
    practiceId,
    patientId,
    indicatorId,
    reason,
    freetext,
    includeAfter12
  ) {
    // update live UI data
    log.excludePatientFromIndicatorInUI(
      practiceId,
      patientId,
      indicatorId,
      reason,
      freetext,
      includeAfter12
    );
    // exclude patient in database
    $.ajax({
      type: 'POST',
      url: `/api/exclude/patient/${patientId}/for/indicator/${indicatorId}/practice/${practiceId}`,
      data: JSON.stringify({ reason, freetext, includeAfter12 }),
      success() {},
      dataType: 'json',
      contentType: 'application/json',
    });
  },

  excludePatientFromAllIndicators(
    practiceId,
    patientId,
    reason,
    freetext,
    includeAfter12
  ) {
    // update live UI data
    log.removeAllPatientExclusionsInUI(practiceId, patientId);
    log.excludePatientFromIndicatorInUI(
      practiceId,
      patientId,
      data.allIndicatorsId,
      reason,
      freetext,
      includeAfter12
    );
    // exclude patient in database
    $.ajax({
      type: 'POST',
      url: `/api/exclude/patient/${patientId}/for/allIndicators/practice/${practiceId}`,
      data: JSON.stringify({ reason, freetext, includeAfter12 }),
      success() {},
      dataType: 'json',
      contentType: 'application/json',
    });
  },

  includePatient(practiceId, patientId, indicatorId) {
    // update live UI data
    if (data.excludedPatients[patientId]) {
      data.excludedPatients[patientId] = data.excludedPatients[
        patientId
      ].filter((v) => v.indicatorId !== indicatorId);
    }
    if (data.excludedPatientsByIndicator[indicatorId]) {
      data.excludedPatientsByIndicator[indicatorId] =
        data.excludedPatientsByIndicator[indicatorId].filter(
          (v) => v !== parseInt(patientId, 10)
        );
    }
    // include patient in database
    $.ajax({
      type: 'POST',
      url: `/api/include/patient/${patientId}/for/indicator/${indicatorId}/practice/${practiceId}`,
      success() {},
      dataType: 'json',
      contentType: 'application/json',
    });
  },

  includePatientForAllIndicators(practiceId, patientId) {
    // update live UI data
    log.removeAllPatientExclusionsInUI(practiceId, patientId);
    // include patient in database
    $.ajax({
      type: 'POST',
      url: `/api/include/patient/${patientId}/for/allIndicators/practice/${practiceId}`,
      success() {},
      dataType: 'json',
      contentType: 'application/json',
    });
  },

  // rwhere
  recordIndividualPlan(
    text,
    practiceId,
    patientId,
    indicatorList,
    assignedUserId,
    done
  ) {
    const dataToSend = {
      actionText: text,
      indicatorList,
      pageId,
      assignedUserId,
    };
    if (lookup.tests && Object.keys(lookup.tests).length > 0)
      dataToSend.tests = lookup.tests;
    $.ajax({
      type: 'POST',
      url: `/api/action/addIndividual/${practiceId}/${patientId}`,
      data: JSON.stringify(dataToSend),
      success(action) {
        notify.showSaved();
        data.addOrUpdatePatientAction(patientId, action, () =>
          done(null, action)
        );
      },
      error(action) {
        notify.showErrorWhileSaving(action.responseText);
        return done(action.responseText);
      },
      dataType: 'json',
      contentType: 'application/json',
    });
  },

  // rwhere
  deleteUserDefinedPatientAction(patientId, actionTextId, callback) {
    $.ajax({
      type: 'DELETE',
      url: `/api/action/userdefinedpatient/${patientId}/${actionTextId}`,
      success(d) {
        notify.showSaved();
        return data.removePatientAction(patientId, actionTextId, () =>
          callback(null, d)
        );
      },
      dataType: 'json',
      contentType: 'application/json',
    });
  },

  // rwhere
  updateIndividualAction(practiceId, patientId, updatedAction, callback) {
    const dataToSend = {
      action: updatedAction,
      url: window.location.href,
      pageId,
    };
    if (lookup.tests && Object.keys(lookup.tests).length > 0)
      dataToSend.tests = lookup.tests;
    $.ajax({
      type: 'POST',
      url: `/api/action/update/individual/${practiceId}/${patientId}`,
      data: JSON.stringify(dataToSend),
      success(action) {
        notify.showSaved();
        if (action.agree === true) {
          return data.addOrUpdatePatientAction(patientId, action, () =>
            callback(null, action)
          );
        } else if (action.agree === false) {
          return data.addOrUpdatePatientAction(patientId, action, () =>
            callback(null, action)
          );
        }
        return data.removePatientAction(patientId, action.actionTextId, () =>
          callback(null, action)
        );
      },
      dataType: 'json',
      contentType: 'application/json',
    });
  },

  updateTeamAction(practiceId, indicatorId, dataProp, done) {
    const dataToSend = { action: dataProp, url: window.location.href, pageId };
    if (lookup.tests && Object.keys(lookup.tests).length > 0)
      dataToSend.tests = lookup.tests;
    $.ajax({
      type: 'POST',
      url: `/api/action/update/team/${practiceId}/${indicatorId}`,
      data: JSON.stringify(dataToSend),
      success(d) {
        if (!done) return notify.showSaved();
        return done(null, d);
      },
      dataType: 'json',
      contentType: 'application/json',
    });
  },

  updateUserDefinedPatientAction(patientId, actionTextId, dataProp, callback) {
    const dataToSend = { action: dataProp };
    if (lookup.tests && Object.keys(lookup.tests).length > 0)
      dataToSend.tests = lookup.tests;
    $.ajax({
      type: 'POST',
      url: `/api/action/update/userdefinedpatient/${patientId}/${actionTextId}`,
      data: JSON.stringify(dataToSend),
      success(action) {
        notify.showSaved();
        action.oldActionTextId = actionTextId;
        return data.addOrUpdatePatientAction(patientId, action, () =>
          callback(null, action)
        );
      },
      dataType: 'json',
      contentType: 'application/json',
    });
  },

  deleteUserDefinedTeamAction(practiceId, actionTextId, done) {
    $.ajax({
      type: 'DELETE',
      url: `/api/action/userdefinedteam/${practiceId}/${actionTextId}`,
      success(d) {
        if (!done) return notify.showSaved();
        return done(null, d);
      },
      dataType: 'json',
      contentType: 'application/json',
    });
  },

  updateUserDefinedTeamAction(practiceId, actionTextId, dataProp, done) {
    const dataToSend = { action: dataProp };
    if (lookup.tests && Object.keys(lookup.tests).length > 0)
      dataToSend.tests = lookup.tests;
    $.ajax({
      type: 'POST',
      url: `/api/action/update/userdefinedteam/${practiceId}/${actionTextId}`,
      data: JSON.stringify(dataToSend),
      success(d) {
        if (!done) return notify.showSaved();
        return done(null, d);
      },
      dataType: 'json',
      contentType: 'application/json',
    });
  },

  recordTeamPlan(practiceId, text, indicatorId, assignedUserId, done) {
    const url = `/api/action/addTeam/${practiceId}/${indicatorId || ''}`;
    const dataToSend = { actionText: text, pageId, assignedUserId };
    if (lookup.tests && Object.keys(lookup.tests).length > 0)
      dataToSend.tests = lookup.tests;
    $.ajax({
      type: 'POST',
      url,
      data: JSON.stringify(dataToSend),
      success(d) {
        notify.showSaved();
        return done(null, d);
      },
      error(d) {
        notify.showErrorWhileSaving(d.responseText);
        return done(d.responseText);
      },
      dataType: 'json',
      contentType: 'application/json',
    });
  },

  assignActionToUser(practiceId, actionObj, userId, done) {
    let url = '/api/action/';
    if (actionObj.actionType === 'patient') {
      url += `individual/assignToUser/${practiceId}/${actionObj.patientId}/${actionObj.actionTextId}/${userId}`;
    } else if (actionObj.actionType === 'team') {
      url += `team/assignToUser/${practiceId}/${actionObj.actionTextId}/${userId}`;
    }
    const dataToSend = { pageId };
    if (lookup.tests && Object.keys(lookup.tests).length > 0)
      dataToSend.tests = lookup.tests;
    $.ajax({
      type: 'POST',
      url,
      data: JSON.stringify(dataToSend),
      success(d) {
        notify.showSaved();
        return done(null, d);
      },
      dataType: 'json',
      contentType: 'application/json',
    });
  },

  updateActionDoneState(practiceId, actionObj, doneState, callback) {
    let url = '/api/action/';
    if (actionObj.actionType === 'patient') {
      url += `individual/updateDoneState/${practiceId}/${actionObj.patientId}/${actionObj.actionTextId}/${doneState}`;
    } else if (actionObj.actionType === 'team') {
      url += `team/updateDoneState/${practiceId}/${actionObj.actionTextId}/${doneState}`;
    }
    const dataToSend = { pageId };
    if (lookup.tests && Object.keys(lookup.tests).length > 0)
      dataToSend.tests = lookup.tests;
    $.ajax({
      type: 'POST',
      url,
      data: JSON.stringify(dataToSend),
      success(d) {
        return callback(null, d);
      },
      dataType: 'json',
      contentType: 'application/json',
    });
  },
};

module.exports = log;
