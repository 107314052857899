const log = require('./log');

const tabs = {};
const actionPlanState = {};

const state = {
  /**
   * Adds a listener to update the saved tab on change
   */
  rememberTabs(identifier) {
    $('a[data-toggle="tab"]').on('shown.bs.tab', (e) => {
      tabs[identifier] = e.target.hash; // e.target new tab
      log.navigateTab(e.target.hash, state.selectedPractice._id);
    });
  },

  /**
   * Gets the current tab hash
   */
  getTab(identifier) {
    return tabs[identifier];
  },

  setActionPlanState(id, val) {
    actionPlanState[id] = val;
  },
  actionPlanState(id) {
    return actionPlanState[id] || undefined;
  },

};

module.exports = state;
