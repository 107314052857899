.modal.fade(tabindex='-1', role='dialog', aria-labelledby='modalLabel', aria-hidden='true')
  .modal-dialog.modal-dialog-top.modal-unauthorised
    .modal-content
      .modal-header
        button.close(type='button', data-dismiss='modal', aria-label='Close')
          span(aria-hidden='true') ×
        h4#modalLabel.modal-title Just a second...
      .modal-body(style='color:#333')
        p(style='text-align:center;color:#222')
          strong You're not yet authorised
        p One of the admin team needs to authorise your account before you can get full access to PINGR.
        p In the meantime, feel free to have a look around, but the data will be missing and a few things might not work correctly.
        p 
          | You will receive an email when you have been authorised, but if you want to chase, please feel free to contact Dr Benjamin Brown at 
          a(href='mailto:benjamin.brown@manchester.ac.uk') benjamin.brown@manchester.ac.uk
        button.btn.btn-default(type='button', data-dismiss='modal') Close
