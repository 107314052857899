const base = require('../base');
const layout = require('../layout');
const lookup = require('../lookup');
const state = require('../state');
const actionList = require('../panels/actionList');
//  actionFilter = require('../panels/actionFilter');

const ID = 'ACTION_PLAN_VIEW';

const ap = {
  create(callback) {
    base.selectTab('actions');
    base.showLoading();

    // use a setTimeout to force the UI to change e.g. show the loading-container
    // before further execution
    setTimeout(() => {
      if (layout.view !== ID) {
        // Not already in this view so we need to rejig a few things - boilerplate
        base.clearBox();
        base.switchToSingleColumn();
        layout.showMainView();

        base.hidePanels(base.farRightPanel);

        layout.view = ID;
      }

      // Have a practice switcher in the title
      let title = `Action plans for ${state.selectedPractice.name}`;
      if (state.practices && state.practices.length > 1) {
        title = `Action plans for <select class='practice-picker'>${state.practices.map(v =>
          `<option value="${
            v._id
          }#agreedactions" ${
            v._id === state.selectedPractice._id ? 'selected' : ''
          } >${
            v.name
          }</option>`)}</select>`;
      }
      base.updateTitle(title);

      lookup.suggestionModalText = 'Screen: Action plan\n===========\n';
      // actionFilter.show(centrePanel);
      actionList.show(base.centrePanel);

      base.wireUpTooltips();

      if (callback) return callback();
      return true;
    }, 0);
  },
};

module.exports = ap;
