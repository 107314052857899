include mixins/thumbs

if noSuggestions
  p(style="padding:10px 15px")  
    | There are 
    strong currently 
    | no improvement action suggestions from PINGR because this patient has either met the quality standard or it is not relevant to them.
    strong Please add your own below.
else 
  table#individual-suggested-actions-table.table(style='margin-bottom:0')
    thead
      tr
        th.action-text-header
        th.thumb-header
    tbody
      - var alt = false
      each suggestion, index in suggestions
        if index === 3 && !visible
          tr
            td(colspan='2')
              a.show-more-than-3
                | Show more than top 3 actions 
                i.fa.fa-caret-down
        tr.suggestion(data-id=suggestion.actionTextId, data-agree=""+suggestion.agree, data-indx=index, class=alt ? '': 'alternate-row', style=(index<3 || visible) ? '' : 'display:none' )
          td
            span(data-subsection=suggestion.subsection)
              strong.black.f20
                != suggestion.actionText
              if suggestion.supportingText
                br
                a.show-more(data-id=suggestion.actionTextId)
                  | Show more 
                  i.fa.fa-caret-down
          td.thumb-cell(align='center')
            +thumbs(suggestion)
        if suggestion.supportingText
          tr.show-more-row(data-id=suggestion.actionTextId, class=alt ? '': 'alternate-row')
            td(colspan='2')
              != suggestion.supportingText
              |Relevant indicators:
              ul
                each indicator in suggestion.indicatorListText
                  li
                    a(href='/#indicators/'+indicator.id.replace(/\./g,"/"))= indicator.text
              span
                = "Number of points this is worth: " + suggestion.pointsPerAction.toFixed(1) + " "
                a.tooltip-on-click(data-toggle='tooltip', data-placement='right', title='A patient is worth a number of percentage points for each indicator they are in. If a patient is in more than one indicator, these points are added together') What does this mean?
              br
              a.show-more(data-id=suggestion.actionTextId)
                | Show less 
                i.fa.fa-caret-up
        - alt = !alt
      if visible
        tr
          td(colspan='2')
            a.show-less-than-3
              | Show top 3 actions only 
              i.fa.fa-caret-up     
