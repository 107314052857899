.modal.fade(tabindex='-1', role='dialog', aria-labelledby='modalLabel', aria-hidden='true')
  .modal-dialog.modal-dialog-top.modal-about
    .modal-content
      .modal-header
        button.close(type='button', data-dismiss='modal', aria-label='Close')
          span(aria-hidden='true') ×
        h4#modalLabel.modal-title About PINGR and us
      .modal-body(style='color:#333')
        p(style='text-align:center;color:#222')
          strong PINGR analyses medical record data against NICE guidelines to help you find patients that may need action and formulate steps to improve their care.
        p 
          | It was created by Dr Benjamin Brown (a GP and health informatics researcher) and Richard Williams (a senior software engineer). PINGR was made possible by funding provided by 
          a(href='https://wellcome.ac.uk',target="_blank",rel="noopener noreferrer") Wellcome Trust
          |  and the National Institute for Health Research (
          a(href='https://www.nihr.ac.uk',target="_blank",rel="noopener noreferrer") NIHR
          | ). If you want to know more then drop Ben a line at 
          a(href='mailto:benjamin.brown@manchester.ac.uk') benjamin.brown@manchester.ac.uk
          | .
        p If you're interested in academic publications, here are some papers about PINGR and the underlying methodology:
        ol
          li 
            | Brown B, Balatsoukas P, Williams R, Sperrin M, Buchan I. 
            strong Multi-method laboratory user evaluation of an actionable clinical performance information system: implications for usability and patient safety. 
            i Journal of Biomedical Informatics. 
            | 2018 Jan. Available from, DOI: 
            a(href='https://doi.org/10.1016/j.jbi.2017.11.008',target="_blank",rel="noopener noreferrer") 10.1016/j.jbi.2017.11.008
          li 
            | Brown B, Balatsoukas P, Williams R, Sperrin M, Buchan I. 
            strong Interface design recommendations for computerised clinical audit and feedback: Hybrid usability evidence from a research-led system. 
            i International Journal of Medical Informatics. 
            | 2016 Nov;94:191–206. Available from, DOI: 
            a(href='https://doi.org/10.1016/j.ijmedinf.2016.07.010',target="_blank",rel="noopener noreferrer") 10.1016/j.ijmedinf.2016.07.010
          li 
            | Brown B, Williams R, Sperrin M, Frank T, Ainsworth J, Buchan I. 
            strong Making audit actionable: an example algorithm for blood pressure management in chronic kidney disease. 
            i AMIA Annual Symposium proceedings. AMIA Symposium. 
            | 2014 Jan;2014:343-52. Available from, Pubmed: 
            a(href='https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4419945/',target="_blank",rel="noopener noreferrer") PMC4419945
        button.btn.btn-default(type='button', data-dismiss='modal') Close
