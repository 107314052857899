include mixins/action-list-row

table.table.action-plan.table(id='suggested-#{actionType}-actions-table', style="width:100%")
  thead
    tr(style='background:#fff;border-top:2px solid #ddd')
      th NHS no.
      th.action-table-header-created Created by
      th.action-table-header-agreed.attach-tooltip-when-hidden(data-toggle="tooltip" data-placement="top" title="The member of your practice who agreed with this action. Only applies to PINGR-suggested actions.") Agreed by
      th.action-table-header-date.attach-tooltip-when-hidden(data-toggle="tooltip" data-placement="top" title="The date this action was agreed with or created.") Date
      th.action-table-header-assigned-to.attach-tooltip-when-hidden(data-toggle="tooltip" data-placement="top" title="The member of your practice this action is currently assigned to.") Assigned to
      th.action-table-header-action-text.attach-tooltip-when-hidden Action
      th.action-table-header-indicators.attach-tooltip-when-hidden(data-toggle="tooltip" data-placement="top" title="The indicator(s) this action relates to.") Indicator(s)
      th.action-table-header-complete-action.attach-tooltip-when-hidden(data-toggle="tooltip" data-placement="top" title="A checkbox in this column means the action can be marked as completed. PINGR-suggested patient actions don't need to be ticked as they will disappear automatically from this plan once the task has been performed.") Completed
  tbody
    each action in actions
      +actionListRow(action)
