.modal.fade(tabindex='-1', role='dialog', aria-labelledby='modalLabel', aria-hidden='true')
  .modal-dialog.modal-dialog-top
    .modal-content
      .modal-header
        button.close(type='button', data-dismiss='modal', aria-label='Close')
          span(aria-hidden='true') ×
        h4#modalLabel.modal-title Suggestions, comments and bugs
      .modal-body
        form
          .form-group
            p.no Please send us any suggestions, comments or bugs you find with PINGR. This could be problems with the data or ideas on how we could improve it.
            p
              strong No need to tell us what page or patient you're referring to - we can see this information already!
            textarea#modal-suggestion-text.form-control(rows='10', style='resize:none')
          button.btn.btn-primary.save-plan(type='submit') Send
          button.btn.btn-default(type='button', data-dismiss='modal') Cancel
      .modal-footer
