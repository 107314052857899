const lookup = require('./lookup');
// require('./polyfills');

// require all the things that get globally added to jquery
// require('bootstrap');
// require('datatables.net-bs')(window, $);
// require('datatables.net-buttons-bs')(window, $);
// require('datatables.net-buttons/js/buttons.colVis.js')(window, $);
// require('datatables.net-buttons/js/buttons.html5.js')(window, $);

const main = require('./main');
const base = require('./base');
const events = require('./events');
const state = require('./state');
const layout = require('./layout');
const tutorial = require('./tutorial');

// TODO not sure why i did this - was in local variable
// maybe a separate module
// window.location = window.history.location || window.location;
/** ******************************************************
 *** Shows the pre-load image and slowly fades it out. ***
 ******************************************************* */
let gotInitialData = false;
let pageIsReady = false;

const getServerParameters = () => {
  lookup.userID = $('#user_id').text().trim();
  lookup.userName = $('#user_fullname').text().trim();
  state.practices = JSON.parse($('#practices').text() || '[]');
  state.selectedPractice = {
    approvers: [],
    _id: 'P87024',
    name: 'Local Medical Centre',
    neighbourhood: 'Something strange',
    ccg: '01G',
    ehr: 'EMIS',
    authorised: true,
  };

  // Only do tutorials if user is authorised
  if (state.selectedPractice.authorised) {
    if ($('#last_login').text() === '') {
      // first login
      $('#tutorialText').text(
        `Welcome to PINGR ${lookup.userName}! Pleased to see you! As this is your first time you might want to check out our tutorials by clicking here.`
      );
      tutorial.intro();
    } else if ($('#last_viewed_tutorial').text() === '') {
      // never seen tutorial
      $('#tutorialText').text(
        `Welcome ${lookup.userName}! Since you last logged in we've added some tutorials which you might find informative. Check them out by clicking here.`
      );
      tutorial.intro();
    } else {
      const lastLogin = new Date($('#last_login').text());
      // const lastViewedTutorial = new Date($('#last_viewed_tutorial').text());

      if ((new Date() - lastLogin) / (1000 * 60 * 60 * 24) > 30) {
        // not logged in for 30 days so show tutorial
        $('#tutorialText').text(
          `Welcome back ${lookup.userName}! It's been a while, so if you need a refresher remember to check out the tutorials by clicking here.`
        );
        tutorial.intro();
      }
    }
  }
};

window.init = function () {
  layout.showPage('main-dashboard');

  // wire up the tutorial
  tutorial.initialize();

  const initialize = () => {
    // Wire up global click/hover listener
    events.listen();
    // Grab the hash if exists - IE seems to forget it
    main.hash = window.location.hash || '#overview';
    // main.hash="#overview";

    // Load the data then wire up the events on the page
    main.init();

    $('[data-toggle="tooltip"]').tooltip({
      container: 'body',
      delay: {
        show: 500,
        hide: 100,
      },
      html: true,
    });
    $('[data-toggle="lone-tooltip"]').tooltip({
      container: 'body',
      delay: {
        show: 300,
        hide: 100,
      },
    });
    $('[data-toggle="lone-tooltip"]').on('shown.bs.tooltip', () => {
      $('[data-toggle="tooltip"]').not(this).tooltip('hide');
    });
  };

  $('body').off('change', '.practice-picker');
  $('body').on('change', '.practice-picker', (e) => {
    const newPracticeId = $(e.currentTarget).val();
    window.location.assign(`/changePracticeTo/${newPracticeId}`);
  });

  getServerParameters();

  if (state.selectedPractice.authorised) {
    // The user is authorised so lets do all the initialisation - including a/b tests

    main.getInitialData(() => {
      gotInitialData = true;
      if (gotInitialData && pageIsReady) {
        initialize();
      }
    });

    /** ****************************************
     *** This happens when the page is ready ***
     ***************************************** */
    $(document).ready(() => {
      // FIXME: shouldn't need this call to getServerParameters
      // getServerParameters();
      pageIsReady = true;
      if (gotInitialData && pageIsReady) {
        initialize();
      }
    });
  } else {
    // the user has logged in prior to authorisation so display something else
    $(document).ready(() => {
      // Grab the hash if exists - IE seems to forget it
      main.hash = window.location.hash || '#overview';
      main.init();

      base.launchUnauthorisedModal();
    });
  }
};
