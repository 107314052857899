#assignUserModal.modal.fade(tabindex='-1', role='dialog', aria-labelledby='exampleModalLabel', aria-hidden='true')
  .modal-dialog
    .modal-content
      .modal-header
        button.close(type='button', data-dismiss='modal', aria-label='Close')
          span(aria-hidden='true') ×
        h4.modal-title Assign action to a new user
      .modal-body
        p
          .form-group
            label.assign-action-team-or-patient
            div.assign-action-nhs(style='padding:4px 10px;')
            div.assign-action-text(style='padding:4px 10px;font-style:italic')
        p
          strong To the following user:
        p
          select.practice-user-list.show-tick(data-live-search='true', data-width='100%', data-live-search-placeholder='Search for a team member...')
            each u in practiceUsers
              option(value=u._id)= u.fullname === currentUserName ? u.fullname + ' (me)' : u.fullname
      .modal-footer
        button.btn.btn-primary.save-action(type='button') Save
        button.btn.btn-default(type='button', data-dismiss='modal') Cancel

#viewActionDetailsModal.modal.fade(tabindex='-1', role='dialog', aria-labelledby='exampleModalLabel', aria-hidden='true')
  .modal-dialog.modal-lg
    .modal-content
      .modal-header
        button.close(type='button', data-dismiss='modal', aria-label='Close')
          span(aria-hidden='true') ×
        h4#exampleModalLabel.modal-title Action information
      .modal-body
        table.table.actionDetailsTable
          tr
            td.actionField Action
            td#actionInfoActionCell
          tr#actionInfoPatientRow
            td.actionField Patient
            td#actionInfoPatientCell
          tr#actionInfoIndicatorsRow
            td.actionField Indicator(s)
            td#actionInfoIndicatorsCell
          tr#actionInfoAgreedByRow
            td.actionField Agreed by
            td#actionInfoAgreedByCell
          tr#actionInfoCreatedByRow
            td.actionField Created by
            td#actionInfoCreatedByCell
          tr#actionInfoAssignedToRow
            td.actionField Assigned to
            td#actionInfoAssignedToCell
          tr#actionInfoCompletedRow
            td.actionField Completed by
            td#actionInfoCompletedDateCell
          tr#actionInfoHistoryRow
            td.actionField History
            td#actionInfoHistoryCell
      .modal-footer
        button.btn.btn-default(type='button', data-dismiss='modal') Close

#removeOrDisagreeModal.modal.fade(tabindex='-1', role='dialog', aria-labelledby='removeOrDisagreeModalLabel', aria-hidden='true')
  .modal-dialog
    .modal-content
      .modal-header
        button.close(type='button', data-dismiss='modal', aria-label='Close')
          span(aria-hidden='true') ×
        h4#removeOrDisagreeModalLabel.modal-title Remove an action from your plan
      .modal-body
        p
          strong You want to remove:
        p
          span.action-text.text-warning(style='font-style:italic')
        form(style='padding-top:5px')
          div.form-group
            label
              | Fair enough! 
            p  Can you tell us why?
          div.form-group
            .radio
              label
                input(type="radio",name="reason",value="remove", required)
                | I just want to remove it from this page
            .radio
              label
                input(type="radio",name="reason",value="disagree", required)
                | I disagree with doing this action.
          div.form-group
            textarea.disagree-why-text.form-control(rows='3', style='resize:none;', placeholder='Please tell us more here...')
      .modal-footer
        button.btn.btn-danger.remove-plan(type='button') Remove
        button.btn.btn-default(type='button', data-dismiss='modal') Cancel

#newUserDefinedTeamActionModal.modal.fade(tabindex='-1', role='dialog', aria-labelledby='newUserDefinedTeamActionModalLabel', aria-hidden='true')
  .modal-dialog
    .modal-content
      .modal-header
        button.close(type='button', data-dismiss='modal', aria-label='Close')
          span(aria-hidden='true') ×
        h4.modal-title 
          | Add a new team action
      .modal-body
        p
          strong Select an indicator
        p
          select.relevant-indicator.show-tick(data-live-search='true', data-width='100%', data-live-search-placeholder='Search for an indicator...')
            option(value='') No indicator
            optgroup(label='Process indicators')
              each i in indicators.processIndicators
                option(value='#{i.id}') !{i.name}
            optgroup(label='Outcome indicators')
              each i in indicators.outcomeIndicators
                option(value='#{i.id}') !{i.name}
        p
          strong What action?
        p
          .form-group
            textarea.form-control.action-text(rows='3', style='resize:none', placeholder="Enter your action here...")
        p
          strong Assign this action to:
        p
          select.practice-user-list.show-tick(data-live-search='true', data-width='100%', data-live-search-placeholder='Search for a team member...')
            each u in practiceUsers
              option(value=u._id)= u.fullname === currentUserName ? u.fullname + ' (me)' : u.fullname
      .modal-footer
        button.btn.btn-primary.create-plan(type='button') Create team action
        button.btn.btn-default(type='button', data-dismiss='modal') Cancel

#newUserDefinedPatientActionModal.modal.fade(tabindex='-1', role='dialog', aria-labelledby='newUserDefinedPatientActionModalLabel', aria-hidden='true')
  .modal-dialog
    .modal-content
      .modal-header
        button.close(type='button', data-dismiss='modal', aria-label='Close')
          span(aria-hidden='true') ×
        h4.modal-title 
          | Add a new patient action
      .modal-body
        p
          strong Which patient?
        p
          form.app-search.ng-pristine.ng-valid
            input.typeahead.form-control.form-control-circle(type='text', placeholder='Search by patients NHS number...' autocomplete="off")
          input.selected-patient-id(type='hidden')
          .well.patient-specs(style='padding:8px 12px;display:none')
            span.nhs(style='margin-right:10px')
            span.sexAndAge
        p
          strong What action? 
        p
          .form-group
            textarea.form-control.action-text(rows='3', style='resize:none', placeholder="Enter your action here...")
        .relevant-indicators-section
          strong Tick any indicators this action applies to:
          div(style="padding-left:5px;max-height:200px;overflow-x:auto;overflow-y:auto")
            .form-group.relevant-indicators
        p
          strong Assign this action to:
        p
          select.practice-user-list.show-tick(data-live-search='true', data-width='100%', data-live-search-placeholder='Search for a team member...')
            each u in practiceUsers
              option(value=u._id)= u.fullname === currentUserName ? u.fullname + ' (me)' : u.fullname
      .modal-footer
        button.btn.btn-primary.create-plan(type='button') Create patient action
        button.btn.btn-default(type='button', data-dismiss='modal') Cancel

include mixins/edit-plan-modal
include mixins/delete-plan-modal
