ul
  li.perf-bullet
    //b!= performance.percentage + '%'
    if aboveTarget
      b#iPercentage(class="text-overPerform")!= performance.percentage + '%'
    else
      b#iPercentage(class="text-underPerform")!= performance.percentage + '%'
    span  (
    span#iFraction= performance.fraction
    span!= ') ' + tagline
  li.perf-bullet
    if type == 'process'
      | The target for this quality indicator is 
      b= target
    else if type == 'outcome'
      | The expected number of patients at your practice is 
      b= performance.expectedNumber
  
  if positiveMessage
    if aboveTarget
      li.perf-bullet.perf-bullet-msg
        span(class="text-overPerform")!= positiveMessage
    else
      li.perf-bullet.perf-bullet-msg
        span(class="text-underPerform")!= positiveMessage
