.panel.panel-default.top-half-of-action-panel(style='margin-bottom:10px')
  .clearfix.panel-heading= "Top 3 suggested actions" + (indicator ? " for indicator '" + indicator + "'" : "" )  + " for this patient"
  #individual-action-panel.panel-body.no-padding
    #advice-list
.panel.panel-default.bottom-half-of-action-panel(style='margin-bottom:5px')
  #user-action-panel.panel-body.no-padding
    #personalPlanIndividual
include mixins/edit-plan-modal
include mixins/delete-plan-modal
include mixins/choose-assigned-user-modal
