const base = require('../base');
const data = require('../data');
const state = require('../state');
const patientList = require('../panels/patientList');
const patientNumeratorList = require('../panels/patientNumeratorList');
const indicatorBreakdown = require('../panels/indicatorBreakdown');
const indicatorBenchmark = require('../panels/indicatorBenchmark');
const indicatorTrend = require('../panels/indicatorTrend');
const indicatorHeadlines = require('../panels/indicatorHeadlines');
const teamActionPlan = require('../panels/teamActionPlan');
const wrapper = require('../panels/wrapper');
const layout = require('../layout');
const lookup = require('../lookup');

const ID = 'INDICATOR';
/*
 * The indicator page consists of the panels:
 *   Tabbed panel
 *     - performance trend
 *     - performance benchmark
 *   Patient groups
 *   Patient list
 *   Team action plan
 */

const dflt = {
  pathwayId() {
    return layout.pathwayId || Object.keys(data.text.pathways)[0];
  },
  pathwayStage(pathwayId) {
    return layout.pathwayStage || Object.keys(data.text.pathways[pathwayId])[0];
  },
  standard(pathwayId, pathwayStage) {
    return layout.standard || Object.keys(data.text.pathways[pathwayId][pathwayStage].standards)[0];
  },
};

const ind = {
  create(
    pathwayId = dflt.pathwayId(),
    pathwayStage = dflt.pathwayStage(pathwayId),
    standard = dflt.standard(pathwayId, pathwayStage),
    tab,
    loadContentFn,
    callback
  ) {
    base.selectTab('indicator');
    base.showLoading();

    // use a setTimeout to force the UI to change e.g. show the loading-container
    // before further execution
    setTimeout(() => {
      if (layout.view !== ID) {
        // Not already in this view so we need to rejig a few things
        base.clearBox();
        base.switchTo2Column1Narrow1Wide();
        layout.showMainView();
        base.hidePanels(base.farRightPanel);
        layout.view = ID;
      }

      if (data.text.pathways[pathwayId] &&
          data.text.pathways[pathwayId][pathwayStage] &&
          data.text.pathways[pathwayId][pathwayStage].standards[standard]) {
        let title = data.text.pathways[pathwayId][pathwayStage].standards[standard].name;

        data.getAllIndicatorData(null, false, (indicators) => {
          const currentIndicatorId = `${pathwayId}.${pathwayStage}.${standard}`; // FIXME - is this accessible from somewhere already?
          if (indicators.length > 1) {
            // Sort/filter indicators
            indicators.sort((a, b) => {
              if (a.name < b.name) return -1;
              if (a.name > b.name) return 1;
              return 0;
            });
            const processIndicators = indicators.filter(v => v.type === 'process');
            const outcomeIndicators = indicators.filter(v => v.type === 'outcome');

            // Build page element
            title = "<select class='indicator-picker'><optgroup label='Process indicators'>";
            title += `${processIndicators.map(v => `<option value="${v.id}" ${v.id === currentIndicatorId ? 'selected' : ''} >${v.name}</option>`)}`;
            title += "</optgroup><optgroup label='Outcome indicators'>";
            title += `${outcomeIndicators.map(v => `<option value="${v.id}" ${v.id === currentIndicatorId ? 'selected' : ''} >${v.name}</option>`)}`;
            title += '</optgroup></select>';

            // Add event listeners
            $('body').off('change', '.indicator-picker');
            $('body').on('change', '.indicator-picker', (e) => {
              base.resetTab('patients');
              const url = `#indicators/${$(e.currentTarget).val().replace(/\./g, '/')}`;
              window.history.pushState(null, null, url);
              loadContentFn(url);
              return false;
            });
          }
          base.updateTitle(title);
        });

        lookup.suggestionModalText =
          `Screen: Indicator\nIndicator: ${
            data.text.pathways[pathwayId][pathwayStage].standards[standard].name
          }\n===========\n`;

        const nothingChanged =
          layout.pathwayId === pathwayId &&
          layout.pathwayStage === pathwayStage &&
          layout.standard === standard &&
          layout.practiceId === state.selectedPractice._id;

        layout.pathwayId = pathwayId;
        layout.pathwayStage = pathwayStage;
        layout.standard = standard;
        layout.practiceId = state.selectedPractice._id;

        // TODO not sure if this needs moving..?
        data.pathwayId = pathwayId;

        // The three panels we need to show
        // Panels decide whether they need to redraw themselves
        // *B* insect this and make sure its not redundant
        teamActionPlan.show(
          base.farLeftPanel,
          `Top 3 suggested actions for ${
            data.text.pathways[pathwayId][pathwayStage].standards[standard].name}`,
          pathwayId,
          pathwayStage,
          standard
        );

        base.updateTab(
          'indicators',
          data.text.pathways[pathwayId][pathwayStage].standards[standard].tabText,
          [pathwayId, pathwayStage, standard].join('/')
        );

        // check state cache (stateMaintainance)
        // RW - added the nothingChanged variable so that this state management
        // is only invoked if the pathway/stage/standard are the same
        // Was leading to a bug where the wrong indicator data was displayed
        const indicatorCachedState = $('#stateM-indicator').children();
        base.savePanelState();
        if (nothingChanged && indicatorCachedState.length > 0) {
          base.farRightPanel.html(indicatorCachedState);
          patientList.restoreFromState();
        }

        // if not presently loaded
        // RW - added the not selector to prevent a blank page appearing in some edge cases
        if (
          $('#mainPage-tabs').not('#stateM-indicator #mainPage-tabs').length < 1
        ) {
          const indcatorType = data.getIndicatorTypeFromPathway(pathwayId, pathwayStage, standard) || 'process';
          const tabList = $('<ul id="mainPage-tabs" class="nav nav-tabs"></ul>');
          const tabContent = $('<div id="mainPage-tab-content" class="indicators-content tab-content"></div>');
          base.farRightPanel.append(tabList);
          base.farRightPanel.append(tabContent);

          let firstTabLabel = 'Improvement opportunities';
          let firstTabTooltip = 'A list of patients with opportunities to improve their care';
          if (indcatorType === 'outcome') {
            firstTabLabel = 'Patients';
            firstTabTooltip = 'A list of your patients with this outcome';
          }

          // *B* 1st tabbed panel
          wrapper.showTab(
            tabContent,
            tabList,
            firstTabLabel,
            firstTabTooltip,
            'Overview',
            [
              {
                show: indicatorHeadlines.show,
                args: [pathwayId, pathwayStage, standard],
                // args: [pathwayId, pathways, standard]
              },
              {
                show: indicatorBreakdown.show,
                args: [
                  pathwayId,
                  pathwayStage,
                  standard,
                  patientList.selectSubsection,
                ],
              },
              {
                show: patientList.show,
                args: [pathwayId, pathwayStage, standard, loadContentFn],
              },
            ],
            true
          );

          if (indcatorType === 'process') {
            // 2nd tabbed panel; process indicators only
            wrapper.showTab(
              tabContent,
              tabList,
              'Patients meeting standard',
              'A list of patients already optimally managed for this indicator',
              'numerator',
              [
                {
                  show: patientNumeratorList.show,
                  args: [pathwayId, pathwayStage, standard, loadContentFn],
                },
              ],
              false
            );
          }

          // 3rd tabbed panel
          wrapper.showTab(
            tabContent,
            tabList,
            'Current and future trend',
            'A line graph of your past and future predicted performance',
            'indicator',
            [
              {
                show: indicatorTrend.show,
                args: [pathwayId, pathwayStage, standard],
              },
            ],
            false
          );

          // 4th tabbed panel
          wrapper.showTab(
            tabContent,
            tabList,
            'Comparison to other practices',
            'A bar chart comparing you to other GP practices',
            'patient',
            [
              {
                show: indicatorBenchmark.show,
                args: [pathwayId, pathwayStage, standard, indcatorType],
              },
            ],
            false
          );

          // setup tab buttons
          wrapper.wireUp(patientList.restoreFromState);
        } else {
          // reload active tab
          $('#mainPage-tabs li.active a').click();
        }

        // Update the performance bit
        const indicatorData = data.processIndicatorsRemoveExcludedPatients(data.indicators.filter(v => v.id === [pathwayId, pathwayStage, standard].join('.')));
        const iFraction = $('#iFraction').text();
        if (
          indicatorData.length > 0 &&
          indicatorData[0].performance.fraction !== iFraction
        ) {
          $('#iPercentage').text(`${indicatorData[0].performance.percentage}%`);
          $('#iFraction').text(indicatorData[0].performance.fraction);
        }

        $('#mainPage-tabs').show();

        base.wireUpTooltips();

        /* setTimeout($('.fp-controlArrow').each(function(idx, el) {
          if(el.is(":visible"))
        }), 2000); */

        // add state indicator
        base.farRightPanel.attr(
          'class',
          'col-lg-8 state-indicator-rightPanel'
        );
      } else {
        // This indicator is currently hidden from the user.
        base.switchToSingleColumn();
        $('#centre-panel').append($(`<div class='text-center' style='padding:20px 10px;'>
          <h3> <i class='fa fa-exclamation-triangle text-warning'></i> You can't see this indicator right now</h3>
          <p style='padding-top:10px'>This may be because you've chosen to hide the indicator.</p>
          <p>You can choose which indicators to show or hide on your <a href="preferences">Preferences page</a>.</p>
        </div>`));
      }

      base.hideLoading();

      // Emit an event to announce that the Indicator tab has completed loading
      $(document).trigger('PINGR-indicatorTab-loaded');

      if (callback) return callback();
      return true;
    }, 0);
  },
};

module.exports = ind;
