const base = require('../base');
const data = require('../data');
const state = require('../state');
const log = require('../log');
const qualityStandardTemplate = require('../templates/quality-standard.jade');
const modalExcludeTemplate = require('../templates/modal-exclude.jade');
const modalExcludeFromAllTemplate = require('../templates/modal-exclude-from-all.jade');

const deleteRow = (row, callback) => {
  const $rowToAnimate = $(row.children('td').css({ backgroundColor: 'red', color: 'white' }));
  let hideCount = $rowToAnimate.length;
  setTimeout(() => {
    $rowToAnimate
      .animate({ paddingTop: 0, paddingBottom: 0 }, 500)
      .wrapInner('<div />')
      .children()
      .slideUp(500, () => {
        hideCount -= 1;
        if (hideCount > 0) return undefined;
        $rowToAnimate
          .closest('tr')
          .remove();
        return callback();
      });
  }, 350);
};

const qs = {
  actionPlanRefresh: {},
  currentIndicatorId: undefined,

  create(patientId, pathwayId, pathwayStage, standard) {
    qs.currentIndicatorId = pathwayId && pathwayStage && standard ? `${pathwayId}.${pathwayStage}.${standard}` : undefined;
    const patientData = data.getPatientData(patientId);

    const tmpl = qualityStandardTemplate;
    // RW TEMP fix
    patientData.standards = patientData.standards
      .map((v) => {
        if (!v.indicatorId) {
          let iid;
          Object.keys(data.text.pathways).forEach((vv) => {
            Object.keys(data.text.pathways[vv]).forEach((vvv) => {
              Object.keys(data.text.pathways[vv][vvv].standards).forEach((vvvv) => {
                if (
                  data.text.pathways[vv][vvv].standards[vvvv].tabText ===
                    v.display
                ) {
                  iid = [vv, vvv, vvvv].join('.');
                }
              });
            });
          });
          if (iid) v.indicatorId = iid;
        }
        if (v.indicatorId) {
          v.excluded = data.isExcluded(patientId, v.indicatorId);
          if (v.excluded) {
            v.excludedTooltip = data.getExcludedTooltip(
              patientId,
              v.indicatorId
            );
          }
          v.indicatorDescription =
            data.text.pathways[v.indicatorId.split('.')[0]][
              v.indicatorId.split('.')[1]
            ].standards[v.indicatorId.split('.')[2]].description;
        }
        return v;
      })
      .sort((a, b) => {
        if (a.excluded && b.excluded) return 0;
        if (a.excluded === b.excluded) {
          if (a.targetMet === b.targetMet) return 0;
          else if (a.targetMet) return 1;
          return -1;
        }
        if (a.excluded) return 1;
        return -1;
      });

    const processStandards = patientData.standards.filter(v => v.type === 'process');
    const outcomeStandards = patientData.standards.filter(v => v.type === 'outcome');

    // If we're viewing in the context of an indicator, show the appropriate quality standards tab
    const indicatorId = pathwayId && pathwayStage && standard ? [pathwayId, pathwayStage, standard].join('.') : null;
    let tabToShow;
    if (processStandards.filter(s => s.indicatorId === indicatorId).length > 0) {
      tabToShow = '#processIndicatorsQS';
    } else if (outcomeStandards.filter(s => s.indicatorId === indicatorId).length > 0) {
      tabToShow = '#outcomeIndicatorsQS';
    }

    const templateData = {
      noStandards: patientData.standards.length === 0,
      processStandards,
      outcomeStandards,
      indicatorId,
      patientId,
      selectedTab: tabToShow || state.getTab('individual'),
    };
    if (data.isExcludedFromAllIndicators(patientId)) {
      templateData.excludedFromAllIndicators = true;
      templateData.excludedFromAllIndicatorsTooltip =
        data.getExcludedFromAllIndicatorsTooltip(patientId);
    }
    const html = tmpl(templateData);

    return html;
  },

  show(
    panel,
    isAppend,
    patientId,
    pathwayId,
    pathwayStage,
    standard,
    refreshFn
  ) {
    qs.actionPlanRefresh = refreshFn;

    const html = qs.create(patientId, pathwayId, pathwayStage, standard);

    if (isAppend) panel.append(html);
    else {
      //* b* maintain state
      base.savePanelState();
      panel.html(html);
    }

    panel
      .off('click', '.panel-heading-toggle')
      .on('click', '.panel-heading-toggle', (e) => {
        const $togglerRow = $(e.currentTarget);
        const $container = panel.find('.standardsPanelBody:first');
        $container.slideToggle('fast', () => {
          $togglerRow.toggleClass('open');
        });
      })
      .off('click', '.reason-link')
      .on('click', '.reason-link', (e) => {
        const action = $(e.currentTarget).find('span:visible').text().toLowerCase();
        panel.find('.standardsPanelBody .qs-show-more-row').hide();
        panel.find('.standardsPanelBody span.show-more-text').removeClass('hidden');
        panel.find('.standardsPanelBody span.show-less-text').addClass('hidden');
        if (action.indexOf('more') > -1) {
          panel
            .find(`.standardsPanelBody .qs-show-more-row[data-id="${$(e.currentTarget).data('id')}"]`)
            .show('fast');
          $(e.currentTarget).find('span.show-more-text').addClass('hidden');
          $(e.currentTarget).find('span.show-less-text').removeClass('hidden');
        }
        e.preventDefault();
      })
      .off('click', '.exclude')
      .on('click', '.exclude', (e) => {
        const tmpl = modalExcludeTemplate;
        const indicatorId = $(e.currentTarget).data('id');
        const bits = indicatorId.split('.');
        const row = $(e.currentTarget).parents('tr:first');

        $('#modal').html(tmpl({
          nhs: data.getNHS(state.selectedPractice._id, patientId),
          indicator:
              data.text.pathways[bits[0]][bits[1]].standards[bits[2]].tabText,
        }));
        $('#modal .modal')
          .off('change', 'input[name=reason]')
          .on('change', 'input[name=reason]', (changeEvent) => {
            const reason = $(changeEvent.target).val();
            const $cb = $('#modal .modal').find('input[name=includeAfter12Months]');
            if (reason === 'other') {
              $cb.prop('disabled', false);
              $cb.parents('.checkbox:first').removeClass('disabled');
            } else {
              $cb
                .prop('disabled', true)
                .prop('checked', false);
              $cb.parents('.checkbox:first').addClass('disabled');
            }
          });
        $('#modal .modal')
          .off('submit', 'form')
          .on('submit', 'form', (submitEvent) => {
            const freetext = $('#modal textarea').val();
            const includeAfter12 = $('#modal input[name=includeAfter12Months]').is(':checked');
            log.excludePatientFromIndicator(
              state.selectedPractice._id,
              patientId,
              indicatorId,
              $('[name="reason"]:checked').val(),
              freetext,
              includeAfter12
            );
            // hide row and refresh panels
            deleteRow(row, () => {
              qs.actionPlanRefresh(patientId, qs.currentIndicatorId);
              qs.updateFromId(patientId, qs.currentIndicatorId);
            });
            submitEvent.preventDefault();
            $('#modal .modal').modal('hide');
          })
          .modal();
      })
      .off('click', '.excludeAll')
      .on('click', '.excludeAll', () => {
        const tmpl = modalExcludeFromAllTemplate;
        $('#modal').html(tmpl({ nhs: data.getNHS(state.selectedPractice._id, patientId) }));
        $('#modal .modal')
          .off('change', 'input[name=reason]')
          .on('change', 'input[name=reason]', (changeEvent) => {
            const reason = $(changeEvent.target).val();
            const $cb = $('#modal .modal').find('input[name=includeAfter12Months]');
            if (reason === 'other') {
              $cb.prop('disabled', false);
              $cb.parents('.checkbox:first').removeClass('disabled');
            } else {
              $cb
                .prop('disabled', true)
                .prop('checked', false);
              $cb.parents('.checkbox:first').addClass('disabled');
            }
          });
        $('#modal .modal')
          .off('submit', 'form')
          .on('submit', 'form', (submitEvent) => {
            const freetext = $('#modal textarea').val();
            const includeAfter12 = $('#modal input[name=includeAfter12Months]').is(':checked');
            log.excludePatientFromAllIndicators(
              state.selectedPractice._id,
              patientId,
              $('[name="reason"]:checked').val(),
              freetext,
              includeAfter12
            );
            // refresh panels
            qs.actionPlanRefresh(patientId, qs.currentIndicatorId);
            qs.updateFromId(patientId, qs.currentIndicatorId);
            submitEvent.preventDefault();
            $('#modal .modal').modal('hide');
          })
          .modal();
      })
      .off('click', '.include')
      .on('click', '.include', (e) => {
        const indicatorId = $(e.currentTarget).data('id');
        log.includePatient(state.selectedPractice._id, patientId, indicatorId);
        qs.actionPlanRefresh(patientId, qs.currentIndicatorId);
        qs.updateFromId(patientId, qs.currentIndicatorId);
      })
      .off('click', '.includeAll')
      .on('click', '.includeAll', () => {
        log.includePatientForAllIndicators(state.selectedPractice._id, patientId);
        qs.actionPlanRefresh(patientId, qs.currentIndicatorId);
        qs.updateFromId(patientId, qs.currentIndicatorId);
      });

    // Emit an event to announce that the Patient tab Quality Standards list has completed loading
    $(document).trigger('PINGR-patientTab-qualityStandards-loaded');
  },

  updateFromId(patientId, indicatorId) {
    if (indicatorId) {
      const bits = indicatorId.split('.');
      qs.update(patientId, bits[0], bits[1], bits[2]);
    } else {
      qs.update(patientId);
    }
  },

  update(patientId, pathwayId, pathwayStage, standard) {
    const html = qs.create(patientId, pathwayId, pathwayStage, standard);
    $('#qs').replaceWith(html);
    base.wireUpTooltips();
  },

};

module.exports = qs;
