div
  ul.nav.nav-tabs(role="tablist")
    li(class=!selectedTab || selectedTab==='#outcomeIndicators' ? "active" : "",role="presentation")
      a.pastel-pink(href="#outcomeIndicators",aria-controls="outcomeIndicators",role="tab",data-toggle="tab",style="margin-top:0")= "Outcome indicators (" + outcomeIndicators.length + ")"
    li(class=selectedTab==='#processIndicators' ? "active" : "",role="presentation")
      a.pastel-pink(href="#processIndicators",aria-controls="processIndicators",role="tab",data-toggle="tab",style="margin-top:0")= "Process indicators (" + processIndicators.length + ")"

  .tab-content    
    #outcomeIndicators.tab-pane.fade(class=!selectedTab || selectedTab==='#outcomeIndicators' ? "active in" : "",role="tabpanel")
      .panel.panel-default.square-panel
        .panel-body.pastel-pink.overview-table.no-padding
          div.hidden-outcome-indicators-warning.text-center(style="padding:6px 6px 0px 6px;display:none")
            div
              i.fa.fa-fw.fa-exclamation-triangle.text-warning
              span.text-warning Some outcome indicators are hidden.
              a(href='preferences') Click here to change your preferences.
          table#overview-table-outcomes.table.table-condensed.order-column.hover
            thead
              tr(style='background-color:#fff9ff')
                th Clinical Area
                th Indicator
                th Number of patients
                th Expected number
                th Change from Yesterday
                th(width="50px") 1 Year Trend
            tbody
              each indicator in outcomeIndicators
                tr.standard-row(data-id=indicator.id,data-description=indicator.description,data-toggle='tooltip', data-placement='left', title='Click for more detail')
                  td= indicator.clinicalArea
                  td
                    strong!= indicator.name
                    br
                    a.show-more(data-id=indicator.id)
                      | Show more 
                      i.fa.fa-caret-down
                  td= indicator.performance.number
                  td= indicator.performance.expectedNumber
                    //- strong(class=indicator.aboveTarget ? 'text-overPerform' : 'text-underPerform')= indicator.performance.percentage + '%'
                    //- if isNaN(indicator.performance.percentage)
                    //-   strong(class = 'text-underPerform') N/A
                    //- else if indicator.aboveTarget
                    //-   if ((indicator.benchmark*100).toFixed(0)) < indicator.performance.percentage
                    //-     strong(class = 'text-topTen')= indicator.performance.incidence + ' per 1000'
                    //-   else
                    //-     strong(class = 'text-overPerform')= indicator.performance.incidence + ' per 1000'
                    //- else
                    //-   strong(class = 'text-underPerform')= indicator.performance.incidence + ' per 1000'
                      
                    //- //strong(class=indicator.aboveTarget ? 'text-overPerform' : 'text-underPerform')= indicator.performance.percentage + '%'
                  //-   //- span= ' (' + indicator.performance.fraction + ')'
                  //- td= indicator.performance.incidenceMultiple + ' per 1000'
                  //- td= indicator.performance.incidenceStandardised ?  indicator.performance.incidenceStandardised + ' per 1000' : 'TBA'
                  //- td= (indicator.benchmark*100).toFixed(0)+"%"
                  td(style="text-align:center")
                    if indicator.change==="up"
                      i.fa.fa-2x.fa-fw.fa-caret-up
                    else if indicator.change==="down"
                      i.fa.fa-2x.fa-fw.fa-caret-down
                    else
                      i.fa.fa-fw.fa-minus
                  td(width="50px")
                    span.inlinesparkline= indicator.trend
    #processIndicators.tab-pane.fade(class=selectedTab==='#processIndicators' ? "active in" : "",role="tabpanel")
      .panel.panel-default.square-panel
        .panel-body.pastel-pink.overview-table.no-padding
          div.hidden-process-indicators-warning.text-center(style="padding:6px 6px 0px 6px;display:none")
            div
              i.fa.fa-fw.fa-exclamation-triangle.text-warning
              span.text-warning Some process indicators are hidden.
              a(href='preferences') Click here to change your preferences.
          table#overview-table-process.table.table-condensed.order-column.hover
            thead
              tr(style='background-color:#fff9ff')
                th.ellipsis-if-small Clinical Area
                th Indicator
                th 
                  span.visible-lg-inline Current Performance
                  span.hidden-lg Perf
                th Actioned Patients
                th Salford Target
                th 
                  span.visible-lg-inline Top 10% Benchmark
                  span.hidden-lg Top 10% B'mark 
                th Change from Yesterday
                th(width="50px") 1 Year Trend
            tbody
              each indicator in processIndicators
                tr.standard-row(data-id=indicator.id,data-description=indicator.description,data-toggle='tooltip', data-placement='left', title='Click for more detail')
                  td.ellipsis-if-small= indicator.clinicalArea
                  td
                    strong!= indicator.name
                    br
                    a.show-more(data-id=indicator.id)
                      | Show more 
                      i.fa.fa-caret-down
                  td
                    if (indicator.performance.percentage === null || isNaN(indicator.performance.percentage))
                      strong N/A
                    else if indicator.aboveTarget
                      if ((indicator.benchmark*100).toFixed(0)) < indicator.performance.percentage
                        strong(class = 'text-topTen')= indicator.performance.percentage + '%'
                      else
                        strong(class = 'text-overPerform')= indicator.performance.percentage + '%'
                    else
                      strong(class = 'text-underPerform')= indicator.performance.percentage + '%'
                      
                    span= ' (' + indicator.performance.fraction + ')'
                  td= indicator.reviewed + "/" + indicator.patientsWithOpportunity
                  td= indicator.target
                  td= (indicator.benchmark*100).toFixed(0)+"%"
                  td(style="text-align:center")
                    if indicator.change==="up"
                      i.fa.fa-2x.fa-fw.fa-caret-up
                    else if indicator.change==="down"
                      i.fa.fa-2x.fa-fw.fa-caret-down
                    else
                      i.fa.fa-fw.fa-minus
                  td
                    div(style='width:50px;overflow:hidden')
                      span.inlinesparkline= indicator.trend

